import React, { useState, useEffect } from "react";
import clsx from 'clsx';

import { useTheme, ThemeProvider } from "@material-ui/core/styles";
import {GlobalTheme} from "../themes/GlobalTheme"

import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import Footer from "./Footer"
import { Box, Drawer, Grid, Divider, Toolbar, Typography, AppBar } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';

import AccountMenu from "../components/Account/AccountMenu"
import { GoNestorLogoSimple } from "../components/Widgets/CustomLayoutAndSurfaces"

import { makeStylesGlobal } from "../themes/GlobalTheme"


import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import CssBaseline from '@material-ui/core/CssBaseline';

import {setLocale} from "react-redux-i18n";


import AdminRouter from "../routes/AdminRouter"
import AdminMenu from "../components/Admin/AdminMenu"

const drawerWidth = 160;


const useStyles = makeStylesGlobal((theme) => ({

  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  Spacer: {
    flexGrow: 1
  },
  MiniSpacer: {
    minWidth: "50px"
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 54, 
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 0,
    ...theme.mixins.toolbar,
  },
  content: {
      padding: theme.spacing(1),
      width: "100%",
      minHeight: "100vh",
  },

  PositionedItemIcon : {
    marginLeft: theme.spacing(1)
  },


}));


export default function AdminLayout({location, match}) {
  const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const validated = useSelector((state) => state.auth.isValidated);
  
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const locale = useSelector(state => state.i18n.locale);


  const [openDrawer, setOpenDrawer] = useState(false)

  const handleDrawerOpen = () => { // replace by a 1 line toggle 
    setOpenDrawer(true); 
  };

  useEffect(() => {
    if((match.path === "/admin") && (locale !== "en" )){
      dispatch(setLocale("en")) 
    }
  }, [match])

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };


  if (authenticated && !validated) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }

  return (
    <ThemeProvider theme={GlobalTheme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar 
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: openDrawer,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: openDrawer,
              })}
            >
              <MenuIcon/>
            </IconButton>
            <Grid item container alignItems="center">
              <Grid item>
                <GoNestorLogoSimple height={55} width={150} />
              </Grid>
              <Grid item className={classes.MiniSpacer}/>
              <Grid item>
                <Typography variant="h5">
                  Administration v1.32
                </Typography>
              </Grid>
              <Grid item className={classes.Spacer}/>
              <AccountMenu layoutType={match.path} />
            </Grid>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: openDrawer,
            [classes.drawerClose]: !openDrawer,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: openDrawer,
              [classes.drawerClose]: !openDrawer,
            }),
          }}
          open={openDrawer}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          <AdminMenu/>
        </Drawer>

        <Box style={{width: "100%"}}>
          <main className={classes.content}>
            <Box height="100%" id="dataViewport">
              <div className={classes.toolbar} id="toolbarViewport"></div>
              <AdminRouter/>
            </Box>  
          </main>
          <Footer/>
        </Box>
        
      </div>
    </ThemeProvider>
  );
}
