import React, { useRef, useState } from "react";
import { Grid, TablePagination } from "@material-ui/core";
import { useSelector } from "react-redux";

import MaterialTable, { MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";
import { makeStylesGlobal } from "../../themes/GlobalTheme"

import { SYNCEVENTSURL } from "../../js/constants/model_cst"
import { fetchData, editModel } from "../../js/utils/backend"

import { DateTimeHourRenderer } from "../Widgets/TableEditors"
import * as cst from "../../js/constants/miscellaneous_cst"; 
import { useReduxPrefsState } from "../../js/actions/prefs"

import { boolRender } from "../Widgets/TableEditors";

var I18n = require('react-redux-i18n').I18n;


const localStyle = makeStylesGlobal(theme => ({

}));


const SyncEvents = (props) => {

    const [isTableEditing, setIsTableEditing] = useState(false);

    const token = useSelector(state => state.auth.token);
    const classes = localStyle();
    const tableRef = useRef();


    const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);

    function refreshTable() { 
        if (tableRef && tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }


    return (
        <>
        <Grid container>
            <Grid item xs={12}>
                <MaterialTable
                    tableRef={tableRef}
                    title={"Synchronization with Sportlink API"}
                    columns={[
                        {
                            title: "When", 
                            field: "Timestamp", 
                            editable: "never", 
                            defaultSort: 'desc',
                            cellStyle: {width: '150px'},
                            render: rowData => DateTimeHourRenderer(150, rowData.Timestamp)
                        },
                        {
                            title: "Info", 
                            field: "Remark", 
                            editable: "never"
                        },
                        {
                            title: "Retry",
                            field: "ForceRestart",
                            type: "boolean",
                            headerStyle: { textAlign: "center"},
                            cellStyle: { textAlign: "center"},
                            render: rowData => boolRender(rowData.ForceRestart)
                        }
                    ]}
                    data={query => fetchData(token, query, SYNCEVENTSURL)}
                    options={{
                        pageSize: rows,
                        actionsColumnIndex: 50,
                        pageSizeOptions: cst.itemsPerPage,
                        emptyRowsWhenPaging:false,
                        sorting: true,
                        search: true,
                    }}
                    onChangeRowsPerPage={(nbRows)=>setRows(nbRows)}
                    editable={{
                        onRowUpdate: (newData, oldData) => 
                            editModel(newData, newData.ID,token, SYNCEVENTSURL)
                    }} 
                    components={{
                        EditRow: props => { setIsTableEditing(true); return (<MTableEditRow {...props} />)},
                        Row: props => { setIsTableEditing(true); return (<MTableBodyRow {...props} />)},
                        Pagination: props=>(<TablePagination {...props} className={isTableEditing ?classes.disabled:null} />) ,
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} /> 
                            </div>
                        )
                    }}
                />
            </Grid>
        </Grid>
        </>
    )
}

export default SyncEvents;