import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { Typography, Grid, Box, Divider, Link } from "@material-ui/core";
import { GoPrimaryButton, VTextField, VSelectInput, VPhoneNumber, VDatePicker, VSwitcher } from "../Widgets/CustomInputs"
import { GoPaper, GoError, CenteredGrid } from "../Widgets/CustomLayoutAndSurfaces"
import { makeStylesGlobal, GlobalTheme } from "../../themes/GlobalTheme"
import RouterLink from "../../routes/RouterLink"
import * as cst from "../../js/constants/miscellaneous_cst"
import { useForm, FormContext } from 'react-hook-form';
import { PhoneNumberValidation } from "../../js/utils/validations"
import { computedCountriesArray } from "../../js/utils/computed"
import Axios from "axios";
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authenticated } from "../../js/actions/auth"
import AvatarPicker from "../Widgets/AvatarPicker";
var I18n = require('react-redux-i18n').I18n;




const useStyles = makeStylesGlobal((theme) => ({
}));

const RegistrationForm = () => {
    const classes = useStyles();

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",

    })

    const locale = useSelector(state => state.i18n.locale);

    const dispatch = useDispatch();
    const { watch, handleSubmit, triggerValidation } = methods;

    const [submitError, setSubmitError] = useState(null);
    const [redirectToLogin, setRedirectToLogin] = useState(false)

    const [countriesList, setCountriesList] = useState([])

    useEffect(() => {
        setCountriesList(computedCountriesArray())
    }, []);

    const onSubmit = data => {
        let url = `/user/register`
        Axios.post(url, data)
            .then(response => {
                if (response.data.IsLogged === true) {
                    dispatch(authenticated(
                        response.data.IsValidated,
                        response.data.Token,
                        response.data.Role
                    ));

                    setRedirectToLogin(true)
                }
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.message) {
                    setSubmitError(error.response.data.message);
                } else {
                    setSubmitError("Impossible to register a new user at this time, please try again later.")
                }
            })
    }


    if (redirectToLogin === true) {
        return <Redirect component={RouterLink} to="/login" />
    }

    return (
        <div className={classes.GoBackgroundColor}>
            <CenteredGrid>
                <ThemeProvider theme={GlobalTheme}>
                    <Box width={700}>
                        <FormContext {...methods}>
                            <GoPaper elevation={3}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="h4">
                                                {I18n.t("view.registerForm.title")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">
                                                {I18n.t("view.registerForm.subtitle")} {`\u00A0`}
                                                <Link component={RouterLink} to="/login">
                                                    {I18n.t("common.links.signIn")}
                                                </Link>
                                            </Typography>
                                            <Divider variant="fullWidth" />
                                        </Grid>
                                        <Grid item sm={2} xs={4}>
                                            <VSelectInput
                                                label={I18n.t("common.fields.gender")}
                                                fieldName="Gender"
                                                allValues={
                                                    (locale === "en") ? cst.genderListEN : ( (locale === "fr") ? cst.genderListFR : cst.genderListNL ) 
                                                    
                                                }
                                            />
                                        </Grid>
                                        <Grid item sm={5} xs={8}>
                                            <VTextField
                                                label={I18n.t("common.fields.firstname")}
                                                fieldName="FirstName"
                                                validation={{ required: true }}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                        <Grid item sm={5} xs={12}>
                                            <VTextField
                                                label={I18n.t("common.fields.lastname")}
                                                fieldName="LastName"
                                                validation={{ required: true }}
                                                fullWidth={true}
                                            />
                                        </Grid>

                                        <Grid item sm={6} xs={12}>
                                            <VTextField
                                                label={I18n.t("common.fields.email")}
                                                fieldName="EMail"
                                                validation={{
                                                    required: true,
                                                    pattern: {
                                                        value: cst.emailRegex,
                                                        message: `${I18n.t("common.helperText.emailInvalid")}`//cst.msgEmailInvalid
                                                    }
                                                }}
                                                fullWidth={true}
                                                onBlur={async () => {
                                                    triggerValidation("ConfirmEMail")
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <VTextField
                                                label={I18n.t("common.fields.confirmEmail")}
                                                fieldName="ConfirmEMail"
                                                fullWidth={true}
                                                validation={{
                                                    required: true,
                                                    validate: (value) => { return value === watch('EMail') ? true : `${I18n.t("common.helperText.emailNotMatching")}`}

                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <VTextField
                                                label={I18n.t("common.fields.password")}
                                                fieldName="Password"
                                                type="password"
                                                validation={{
                                                    required: true,
                                                    pattern: {
                                                        value: cst.passwordRegex,
                                                        message: `${I18n.t("common.helperText.passwordInvalid")}`//cst.msgPasswordInvalid
                                                    }
                                                }}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <VTextField
                                                label={I18n.t("common.fields.confirmPassword")}
                                                fieldName="ConfirmPassword"
                                                fullWidth={true}
                                                type="password"
                                                validation={{
                                                    required: true,
                                                    validate: (value) => { return value === watch('Password') ? true : `${I18n.t("common.helperText.passwordNotMatching")}`}
                                                }}
                                            />
                                        </Grid>


                                        <Grid container item sm={6} xs={12} wrap="wrap" >
                                            <Grid item xs={12}>
                                                <Box >
                                                    <VPhoneNumber
                                                        label={I18n.t("common.fields.mobile")} //// to be restricted to Benelux & France/Germany ? 
                                                        fieldName="GSM"
                                                        validation={{
                                                            required: true,
                                                            validate: (value) => { return PhoneNumberValidation(value) },
                                                        }}
                                                        fullWidth={true}
                                                        helperText={I18n.t("common.helperText.mobileInvalid")}
                                                    />
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Box>
                                                    <VDatePicker
                                                        label={I18n.t("common.fields.birthDate")}
                                                        fieldName="BirthDate"
                                                        validation={{ required: false }}
                                                        fullWidth={true}
                                                        openTo="year"
                                                        isFilled={false}
                                                    />
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Box mt={2}>
                                                    <VSelectInput
                                                        label={I18n.t("common.fields.language")}
                                                        fieldName="Language"
                                                        validation={{ required: false }}
                                                        fullWidth={true}
                                                        allValues={cst.languageList}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <Grid container item sm={6} xs={12}>
                                            <AvatarPicker
                                                fieldName="Avatar"
                                                label="Avatar"
                                                validation={{ required: false }}
                                                fileType="img"
                                            />
                                        </Grid>

                                        <Grid item sm={6} xs={12}>
                                            <VSwitcher
                                                fieldName="IsReferee"
                                                label="Umpire"
                                            />
                                        </Grid>

                                        {submitError ?
                                            <Grid item xs={12}>
                                                <GoError>{submitError}</GoError>
                                            </Grid> : null
                                        }

                                        <Grid item xs={12}>
                                            <GoPrimaryButton type="submit" onClick={handleSubmit} fullWidth={true}>
                                                {I18n.t("common.button.createAccount")}
                                            </GoPrimaryButton>
                                        </Grid>

                                    </Grid>
                                </form>
                            </GoPaper>
                        </FormContext>
                    </Box>
                </ThemeProvider>
            </CenteredGrid>
        </div>
    )
}

export default RegistrationForm