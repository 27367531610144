import React, { useRef, useState } from "react";
import { Button, Grid, TablePagination, CircularProgress, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import { useSelector } from "react-redux";

import MaterialTable, { MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";
import { makeStylesGlobal } from "../../themes/GlobalTheme"

import AccountBoxIcon from '@material-ui/icons/AccountBoxOutlined';
import ProfileForm from "../Profile/ProfileForm"

import { USERSURL } from "../../js/constants/model_cst"
import { fetchData, editModel, getDownload, excelSpreadsheetMIME, buildUrl } from "../../js/utils/backend"
import { GoAdminToolbar, GoModal } from "../Widgets/CustomLayoutAndSurfaces";
import { GoPrimaryButton } from '../Widgets/CustomInputs';
import { AvatarThumbnail, refereeRender } from "../Widgets/TableEditors"
import * as cst from "../../js/constants/miscellaneous_cst";
import { useReduxPrefsState } from "../../js/actions/prefs"
import GetAppIcon from '@material-ui/icons/GetApp';
import Axios from 'axios';

import { boolRender } from "../Widgets/TableEditors";

var I18n = require('react-redux-i18n').I18n;


const localStyle = makeStylesGlobal(theme => ({

}));


const Users = (props) => {

    const [isTableEditing, setIsTableEditing] = useState(false);
    const [exporting, setExporting] = useState(false);

    const token = useSelector(state => state.auth.token);
    const classes = localStyle();
    const tableRef = useRef();
    const [openDialog, setOpenDialog] = useState(false);
    const [confirmLabel, setConfirmLabel] = useState("");
    const [currentID, setCurrentID] = useState("");


    const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);
    const [searchQuery, setSearchQuery] = useState(null);

    const [rowUserID, setRowUserId] = useState()

    const [showModal, updateShowModal] = useState(false)
    const toggleModal = () => {
        updateShowModal(state => !state);
        refreshTable();
    }

    function refreshTable() {
        if (tableRef && tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }


    const handleDownloadClick = async () => {
        setExporting(true); // Disable the button before starting the download

        try {
            let url = buildUrl(cst.usersExportToExcel, searchQuery);
            await getDownload(
                url,
                token,
                excelSpreadsheetMIME,
                `Users`
            );

            // After download is complete, enable the button again
            setExporting(false);
        } catch (error) {
            // Handle download errors here
            console.error('Download failed:', error);
            setExporting(false);
        }
    };

    const debounceSearch = debounce((query) => {
        setSearchQuery(query);
    }, 1000); // Adjust the debounce delay as needed (e.g., 500 milliseconds)

    function debounce(func, delay) {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    const handleOpen = (confirmLabel, currentID) => {
        setConfirmLabel(confirmLabel);

        setCurrentID(currentID);
        setOpenDialog(true);

    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleConfirm = () => {
        // Handle your confirmation logic here
        // For example, you can submit the form or perform some action
        // Then, close the modal

        let url = cst.userPath+currentID;

        console.log(url);
          
          let config = {
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            }
        };

        Axios.delete(url, config)
            .then(response => {
                refreshTable();
               // history.goBack
            })
            .catch(error => {
                refreshTable();
            })
        handleClose();

    };


    return (
        <>
            <Grid container>
                <Dialog open={openDialog} onClose={handleClose}>
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you really want to delete {confirmLabel}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            No
                        </Button>
                        <Button onClick={handleConfirm} color="primary">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid item xs={12}>
                    <MaterialTable
                        tableRef={tableRef}
                        title={"Users"}
                        columns={[
                            {
                                title: "Avatar",
                                field: "Avatar",
                                sorting: false,
                                editable: "never",
                                width: "1%",
                                cellStyle: { padding: "4px", width: "80px" },
                                render: rowData => AvatarThumbnail(60, rowData.Avatar)
                            },
                            {
                                title: "First Name",
                                field: "FirstName",
                                editable: "never"
                            },
                            {
                                title: "Last Name",
                                field: "LastName",
                                editable: "never",
                                defaultSort: 'asc'
                            },
                            {
                                title: "Club",
                                field: "ClubName",
                                editable: "never"
                            },
                            {
                                title: "Club Manager",
                                field: "IsClubManager",
                                type: "boolean",
                                headerStyle: { textAlign: "center" },
                                cellStyle: { textAlign: "center" },
                                render: rowData => boolRender(rowData.IsClubManager)
                            },
                            {
                                title: "E-mail",
                                field: "EMail",
                                editable: "never"
                            },
                            {
                                title: "Role",
                                field: "Role",
                                editable: "always",
                                width: "3%",
                                lookup: { Admin: "Admin", User: "User", Locked: "Locked" }
                            },
                            {
                                title: "Referee",
                                field: "IsReferee",
                                type: "boolean",
                                editable: "never",
                                headerStyle: { textAlign: "center" },
                                cellStyle: { textAlign: "center" },
                                render: rowData => refereeRender(rowData.IsReferee, rowData.IsClubOfficial, rowData.IsIndoorReferee, rowData.IsOutdoorReferee, rowData.IsNationalReferee)
                            },
                        ]}
                        data={query => {
                            return fetchData(token, query, USERSURL).finally
                                (response => {

                                    debounceSearch(query);
                                }

                                )
                        }}
                        options={{
                            pageSize: rows,
                            actionsColumnIndex: 50,
                            pageSizeOptions: cst.itemsPerPage,
                            emptyRowsWhenPaging: false,
                            sorting: true,
                            search: true,
                        }}

                        onChangeRowsPerPage={(nbRows) => setRows(nbRows)}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                editModel(newData, newData.ID, token, USERSURL)
                        }}
                        actions={[
                            (rowData) => ({
                                icon: () => <AccountBoxIcon />,
                                onClick: (event, rowData) => {
                                    toggleModal()
                                    setRowUserId(rowData.ID)
                                },
                                tooltip: 'Show profile',
                            }),
                            (rowData) => ({
                                icon: 'delete',
                                tooltip: 'Delete User',
                                onClick: (event, rowData) => { console.log("delete"); handleOpen(rowData.FirstName + " " + rowData.LastName, rowData.ID) },
                            }
                            ),
                        ]}
                        components={{
                            EditRow: props => { setIsTableEditing(true); return (<MTableEditRow {...props} />) },
                            Row: props => { setIsTableEditing(true); return (<MTableBodyRow {...props} />) },
                            Pagination: props => (<TablePagination {...props} className={isTableEditing ? classes.disabled : null} />),
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                    <GoAdminToolbar>
                                        <GoPrimaryButton type="button" startIcon={<GetAppIcon />}
                                            disabled={exporting}
                                            onClick={handleDownloadClick}>Export to Excel

                                        </GoPrimaryButton>
                                        &nbsp;
                                        {exporting ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        ) : null}
                                    </GoAdminToolbar>

                                </div>
                            )
                        }}
                    />
                </Grid>
            </Grid>
            <GoModal showModal={showModal} updateModalState={toggleModal}>
                <ProfileForm
                    rowUserID={rowUserID}
                    isPopUp={true}
                    onClose={() => { toggleModal();  }}
                    
                />
            </GoModal>
        </>
    )
}

export default Users;