import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { computedClubsArray } from "../../js/utils/computed"
import { useSelector } from "react-redux"; 
import { useFormContext } from 'react-hook-form';
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import {
    FormControl, InputLabel, Select, MenuItem, FormHelperText
} from "@material-ui/core"

const localStyle = makeStylesGlobal(theme => ({
}));

export const VClubChooser = ({
    fieldName,
    label, 
    value,
    validation,
}) => {

    const methods = useFormContext();
    const { errors, setValue, register } = methods;
    const classes = localStyle();
    const token = useSelector(state => state.auth.token);
    const isError = errors[fieldName] ? true : false;
        
    const errorMessage = isError ? errors[fieldName].message : "";
    const [currentClub, setCurrentClub] = useState(value);

    const [clubList, setClubList] = useState([])

    useEffect(() => {
        async function fetchClubs () {
            let url = "/club/chooser"
            var config = {
                headers: { Authorization: "Bearer " + token}
            };
            let fetchedClubs = await Axios.get(url, config)
            setClubList(computedClubsArray(fetchedClubs.data))
        }
        fetchClubs()
    }, [])

    useEffect(() => {
        register({ name: fieldName }, validation);
    }, [register, fieldName, validation]);

    return(
        <div className={classes.VComponentMargin}>
            <FormControl error={isError} style={{ fullWidth: true, width: "100%" }}>
                <InputLabel>{label}</InputLabel>
                <Select 
                    label={label} 
                    name={fieldName}
                    value={currentClub}
                    onChange={(event) => {
                        let newClub = clubList.find(element => element.label  === event.target.value)
                        setCurrentClub(event.target.value)
                        setValue(fieldName, newClub.ID, true )
                    }}
                >
                    {clubList && clubList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText hidden={!isError}>{errorMessage}</FormHelperText>
            </FormControl>
        </div>
    )
}