import React, { useRef, useState } from "react";
import { Grid, TablePagination, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import MaterialTable, { MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";
import { makeStylesGlobal } from "../../themes/GlobalTheme"
import { CLUBURL } from "../../js/constants/model_cst"
import { fetchData, deleteModel,getDownload, excelSpreadsheetMIME, buildUrl  } from "../../js/utils/backend"
import * as cst from "../../js/constants/miscellaneous_cst"
import { GoAdminToolbar, GoPaper } from "../Widgets/CustomLayoutAndSurfaces"
import { GoPrimaryButton } from "../Widgets/CustomInputs"
import { AvatarThumbnail } from "../Widgets/TableEditors"
import ClubEditPanel from "../EditPanel/ClubEditPanel"
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { useReduxPrefsState } from "../../js/actions/prefs"
import GetAppIcon from '@material-ui/icons/GetApp';

const localStyle = makeStylesGlobal(theme => ({

}));

const Clubs = (props) => {

    const [isTableEditing, setIsTableEditing] = useState(false);
    const [exporting, setExporting] = useState(false);

    const token = useSelector(state => state.auth.token);
    const classes = localStyle();
    const tableRef = useRef();

    const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);
    const [searchQuery, setSearchQuery] = useState(null);
    const [operation, setOperation] = useState("none")

    const operationChange = newOperation => {
        switch(newOperation) {
            case "none":
                break;
            case "new":
                break;
            default: 
                return null;
        }
        setOperation(newOperation);
    }

    const handleDownloadClick = async () => {
        setExporting(true); // Disable the button before starting the download

        try {
            let url = buildUrl(cst.clubsExportToExcel,searchQuery);
            await getDownload(
                url,
                token,
                excelSpreadsheetMIME,
                `Clubs`
            );

            // After download is complete, enable the button again
            setExporting(false);
        } catch (error) {
            // Handle download errors here
            console.error('Download failed:', error);
            setExporting(false);
        }
    };

    function refreshTable() { 
        if (tableRef && tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }
    const debounceSearch = debounce((query) => {
        setSearchQuery(query);
      }, 1000); // Adjust the debounce delay as needed (e.g., 500 milliseconds)

    function debounce(func, delay) {
        let timer;
        return function (...args) {
          clearTimeout(timer);
          timer = setTimeout(() => {
            func(...args);
          }, delay);
        };
      }

    return (
        <>
        <Grid container>
            <Grid item xs={12}>
                <MaterialTable
                    detailPanel={
                        rowData => {
                            return (
                                <ClubEditPanel
                                    Data={rowData}
                                    onClose={refreshTable}
                                    isNew={false}
                                />
                            )
                        }
                    }
                    tableRef={tableRef}
                    title={"Club"}
                        columns={[
                            {
                                title: "Logo", 
                                field: "Avatar",
                                sorting: false,
                                editable: "never",
                                width: "1%",
                                cellStyle: { padding: "4px", width:"80px"},
                                render: rowData => AvatarThumbnail(60, rowData.Avatar)
                            },
                        {
                            title: "Name", 
                            field: "Name",
                            defaultSort: "asc",
                        }
                    ]}
                    data={query => 
                        {   
                          return fetchData(token, query, CLUBURL).finally
                          ( response =>
                            {
                                
                                debounceSearch(query);
                            }
                            
                          )
                        }}

                    editable={{
                        onRowDelete: oldData => 
                            deleteModel(oldData.ID, token, CLUBURL)
                    }} 
                    options={{
                        pageSize: rows,
                        pageSizeOptions: cst.itemsPerPage,
                        actionsColumnIndex: 50,
                        draggable: false,
                        emptyRowsWhenPaging:false,
                        detailPanelType: 'single',
                        sorting: true,
                        search: true,
                    }}
                    onChangeRowsPerPage={(nbRows)=>setRows(nbRows)}
                    components={{
                        EditRow: props => { setIsTableEditing(true); return (<MTableEditRow {...props} />)},
                        Row: props => { setIsTableEditing(true); return (<MTableBodyRow {...props} />)},
                        Pagination: props=>(<TablePagination {...props} className={isTableEditing ?classes.disabled:null} />) ,
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} /> 
                                <GoAdminToolbar>
                                    {operation === "none" && (
                                        <>
                                            <GoPrimaryButton
                                                startIcon={<AddIcon />}
                                                onClick={() => {
                                                    operationChange("new");
                                                }}
                                            >
                                            New
                                            </GoPrimaryButton>
                                        </>
                                    )}
                                    {operation === "new" && (
                                        <GoPaper>
                                            <ClubEditPanel
                                                onClose={() => {
                                                    operationChange("none");
                                                    refreshTable();
                                                }}
                                                isNew={true}
                                            />
                                        </GoPaper>
                                    )}

                                    <GoPrimaryButton type="button" startIcon={<GetAppIcon />}
                                        disabled={exporting}
                                        onClick={handleDownloadClick}>Export to Excel

                                    </GoPrimaryButton>
                                    &nbsp;
                                    {exporting ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    
                                </GoAdminToolbar>
                            </div>
                        )
                    }}
                />
            </Grid>  
        </Grid>
        
        </>
    )
}

export default Clubs;