import React, {useState, useRef, useEffect} from 'react'
import mapboxgl from 'mapbox-gl'

export default function LocationChooser({latitude, longitude, range, onChange}) {


    const [map, setMap] = useState(null)
    const [marker, setMarker] = useState(null)
    const mapContainer = useRef(null)
  
    let lng = longitude ? longitude : 0
    let lat = latitude ? latitude : 0
    let isDisplayMap = lng != 0 && lat != 0 && lng < 180 && lat < 90 && lng > -180 && lat > -90

    const styles = {
        width: "100%",
        height: "600px",
        position: "relative",
        display: isDisplayMap ? "block" : "none"
    }

    const earth = 6378137
    
    useEffect(() => {

        mapboxgl.accessToken = 'pk.eyJ1IjoiZ29uZXN0b3IiLCJhIjoiY2tlY3dqeHRlMG03dzMzbXRlcmg3OGUwNyJ9.GUhtAV2Ev6XVnhLXLcLJgg'

        const southWest = [
            Number(-range/(earth*Math.cos(Math.PI*lat/180)) * 180/Math.PI) + Number(lng),
            (Number(-range/earth * 180/Math.PI)) + Number(lat)
        ]

        const northEast = [
            Number(range/(earth*Math.cos(Math.PI*lat/180)) * 180/Math.PI) + Number(lng),
            (Number(range/earth * 180/Math.PI)) + Number(lat)
        ]
    
        const bounds = [
            southWest,
            northEast
        ]
        
        const initializeMap = ({setMap, mapContainer}) => {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/gonestor/ckfcalbyj3yf519qvde6xf88p",
                center: [lng, lat],
                maxBounds: bounds,
                zoom: 10,
            })

            map.on("load", () => {
                setMap(map)
                map.resize()
            })

            const marker = new mapboxgl.Marker({
                draggable: true
            })
                .setLngLat([lng,lat])
                .addTo(map)

            function onDragEnd() {
                const newLngLat = marker.getLngLat()
                lng = newLngLat.lng
                lat = newLngLat.lat
                onChange({lng,lat})
            }

            marker.on("dragend", onDragEnd)
            setMarker(marker)
        }

        if (isDisplayMap) {
            if(!map) {
                initializeMap({setMap, mapContainer})
            } else {
                marker.setLngLat([lng,lat])
                map.setMaxBounds(bounds)
                map.flyTo({
                    center: [lng,lat]
                })
            }
        }
        
    },[map,longitude,latitude])

    return (
        <>
            <div ref={el => (mapContainer.current = el)} style={styles} />
        </>
    )

}