import React from 'react';
import AuthRouter from './routes/AuthRouter';
import { ThemeProvider } from '@material-ui/core/styles';
import { GlobalTheme } from './themes/GlobalTheme';



const App = () => (
  <ThemeProvider theme={GlobalTheme}>
        <AuthRouter/>
  </ThemeProvider>);


export default App;