import { combineReducers } from 'redux';
import authReducer from "./auth";
import prefsReducer from "./prefs";
import synctabReducer from "./synctab";

import { i18nReducer } from "react-redux-i18n"


const rootReducer = combineReducers({
    auth: authReducer,
    userprefs: prefsReducer,
    synctab: synctabReducer,
    i18n: i18nReducer 
});

export default rootReducer