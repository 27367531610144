import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { CenteredGrid } from "../Widgets/CustomLayoutAndSurfaces"
import LoginForm from "./LoginForm"
import { Box } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { GlobalTheme, makeStylesGlobal } from "../../themes/GlobalTheme";

import ValidationForm from "../Account/ValidationForm"

const useStyles = makeStylesGlobal((theme) => ({

}));

export default function Login() {
    const classes = useStyles();

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const isValidated = useSelector(state => state.auth.isValidated);
    const originalPath = useSelector(state => state.auth.originalPath);

    
    if(originalPath === "/login") 
    {
        originalPath = "/";
    }

    return (
        <div className={classes.GoBackgroundColor}>
            <CenteredGrid>
                <ThemeProvider theme={GlobalTheme}>
                    <Box width={400}>
                        {isAuthenticated ? <ValidationForm/> : <LoginForm/> } 
                    </Box>
                </ThemeProvider>

                {isAuthenticated && isValidated ? <Redirect to={originalPath} /> :  null}

            </CenteredGrid>
        </div>
    )
}