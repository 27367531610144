import React, {useEffect, useState} from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { tokenAuthenticated, entry } from "../js/actions/auth";
import { updateTab } from "../js/actions/synctab";
import Login from "../components/Account/Login";
import Logout from "../components/Account/Logout";
import ResetPassword from "../components/Account/ResetPassword"
import ScrollToTop from "./ScrollToTop";
import AdminLayout from "../layouts/AdminLayout";
import ChangePassword from "../components/Account/ChangePassword";
import RegistrationForm from "../components/Account/RegistrationForm";
import UserLayout from "../layouts/UserLayout"
import PathChooser from "../components/Account/PathChooser"
import TeamLayout from "../layouts/TeamLayout";
import { isMobile } from 'react-device-detect';
function PrivateRoute({ component: Component, restrictedTo, ...rest }) {
  const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const validated = useSelector((state) => state.auth.isValidated);
  const ok = authenticated && validated;
  const activeRole = useSelector((state) => state.auth.role);
  const appropriate = restrictedTo ? activeRole === restrictedTo : true;
  const dispatch = useDispatch();

  if (!ok) {
    dispatch(entry(document.location.pathname));
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        ok === true ? (
          appropriate === true ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: "/" },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        ) 
      }
    />
  );
}

const AuthRouter = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  let lastAuthentication = useSelector((state) => state.auth.since);

  const [lang, setLang] = useState(useSelector(state => state.i18n.locale))


  useEffect(() => {
    setInterval(() => {
      dispatch(updateTab());
    }, 5000);
  });

  if (token) {
    lastAuthentication = lastAuthentication
      ? new Date(lastAuthentication)
      : new Date(2000, 1, 1);
    const numberHours =
      (new Date().getTime() - lastAuthentication.getTime()) / (1000 * 3600);
    if (numberHours < 1) {
      console.log("Recent authentication found.");
    } else {
      dispatch(tokenAuthenticated(token));
    }
  }

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />

          <Route exact path="/reset-password" component={ResetPassword}/>
          <Route exact path="/change-password" component={ChangePassword}/>
          <Route path="/register" component={RegistrationForm}/>

          <PrivateRoute 
            path="/admin" 
            restrictedTo="Admin"
            component={AdminLayout}
          />

          <PrivateRoute
            path="/team" 
            component={TeamLayout} 
          />

          <PrivateRoute
            path="/user" 
            component={UserLayout} 
          />

          <PrivateRoute 
            path="/chooser" 
            component={PathChooser}
          />
          {isMobile ? <Route exact path="/app" render={() =>  (window.location = "gonestor://")} /> : null}
         <Redirect from="/" to="/chooser"/>
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default AuthRouter;