import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Members from "../components/Team/Members"
import ClubDemands from "../components/Team/ClubDemands"
import ClubPrestations from "../components/Team/ClubPrestations"

import { useSelector } from "react-redux";


export default function TeamRouter() {

    const isClubManager = useSelector((state) => state.auth.isClubManager)

    return (
        
        <Switch> 
            {isClubManager ? 
                <>
                    <Route path="/team/members" component={Members} /> 
                    <Route path="/team/clubdemands" component={ClubDemands} />
                    <Route path="/team/clubprestations" component={ClubPrestations}/>
                    <Redirect from="/" to="/team/members" />
                </>
            : 
                <Redirect to="/"/>
            }
        </Switch>
        
    )
}
