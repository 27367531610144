import React, {useState} from 'react'
import RouterLink from '../../routes/RouterLink'
import { ThemeProvider } from "@material-ui/core/styles";
import { CenteredGrid, GoPaper, GoError } from "../Widgets/CustomLayoutAndSurfaces"
import { VTextField } from "../Widgets/CustomInputs";
import { GlobalTheme, makeStylesGlobal } from "../../themes/GlobalTheme";
import { Typography, Grid, Box, Link} from "@material-ui/core";
import { Redirect } from 'react-router-dom';
import { useForm, FormContext } from 'react-hook-form';
import Axios from 'axios';
import { GoPrimaryButton } from '../Widgets/CustomInputs';
import * as cst from "../../js/constants/miscellaneous_cst"
var I18n = require('react-redux-i18n').I18n;


const localStyle = makeStylesGlobal(theme => ({
    FormTitle: {
        padding: theme.spacing(2),
    }
}));

const ResetPassword = () => {
    const classes = localStyle();

    const methods = useForm({
        mode: "onchange",
        reValidateMode: "onChange"
    })
    const {handleSubmit} = methods;

    const [redirectToLogin, setRedirectToLogin] = useState(false)
    const [submitError, setSubmitError] = useState(null);


    const onSubmit = data => {
        let url = cst.resetPasswordPath
        console.log(url, data);
        Axios.post(url, data)
            .then(response => {
                setRedirectToLogin(true) // elsewhere ? 
            })
            .catch(error => {
                if(error.response && error.response.data && error.response.data.message) {
                    setSubmitError(error.response.data.message);
                } else {
                    setSubmitError(cst.errorPasswordReset)
                }
            })
    }

    if(redirectToLogin === true) {
        return <Redirect component={RouterLink} to="/login" />
    }

    return (
        <div className={classes.GoBackgroundColor}>   
            <CenteredGrid>
                <ThemeProvider theme={GlobalTheme}>
                    <Box width={400}>
                        <GoPaper elevation={3}>
                            <FormContext {...methods}>
                                <form onSubmit={handleSubmit(onSubmit)}> 
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} className={classes.FormTitle}>
                                            <Typography variant="h4">{I18n.t("view.resetPassword.title")}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <VTextField
                                                fieldName="Value"
                                                label="E-mail"
                                                validation={{
                                                    required: true,
                                                    pattern : {
                                                        value: cst.emailRegex,
                                                        message: cst.msgEmailInvalid
                                                    }
                                                }}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                        {submitError ? 
                                        <Grid item xs={12}>
                                                <GoError>{submitError}</GoError>
                                        </Grid> : null}
                                        <Grid item xs={12}>
                                            <GoPrimaryButton type="submit" onClick={handleSubmit} fullWidth={true}>
                                                {I18n.t("common.button.sendEmail")}
                                            </GoPrimaryButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption">
                                                <Link component={RouterLink} to="/login">
                                                    {I18n.t("common.links.backLogin")}
                                                </Link>
                                            </Typography>
                                            
                                        </Grid>
                                    </Grid>
                                </form>
                            </FormContext>
                        </GoPaper>
                    </Box>
                </ThemeProvider>
            </CenteredGrid>
        </div>
    )
}

export default ResetPassword;