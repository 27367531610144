import React from "react";
import { Typography, Grid, Box } from "@material-ui/core";
import { makeStylesGlobal } from "../themes/GlobalTheme"
import { useSelector } from "react-redux";
import { LanguageSelector } from "../components/Widgets/CustomInputs"


const useStyles = makeStylesGlobal((theme) => ({

    mainFooter : {
        color: "white",
        backgroundColor: theme.palette.default.main,
        padding: "2em",
        position: "relative",
        bottom: 0,

    },

}));

const AdminFooter = ({layoutType}) => {
    const classes = useStyles();

    const isClubManager = useSelector((state) => state.auth.isClubManager)
    const isAdminLayout = layoutType === "/admin";
    const isClubLayout = layoutType === "/team";


    return (
        <div className={classes.mainFooter}>
            <div className={classes.row}>
                <Grid container justify="space-between" alignItems="flex-end">
                    <Grid item md={4} sm={6} xs={12}>
                        <Typography variant="caption" >
                        © {new Date().getFullYear()} GoNestor - SB Square. All Rights Reserved.
                        </Typography>
                    </Grid>
                    {isClubManager && isClubLayout && (
                        <LanguageSelector position="footer"/>
                    )}
                </Grid>
            </div>
        </div>
    )
}

export default AdminFooter;
