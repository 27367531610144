import React from "react";
import RouterLink from "../../routes/RouterLink"
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";


import DemandsIcon from '@material-ui/icons/Forum';
import UsersIcon from '@material-ui/icons/AssignmentInd';
import ClubsIcon from '@material-ui/icons/SportsHockey';
import FieldsIcon from '@material-ui/icons/Room'; 

import CategoriesIcon from '@material-ui/icons/Class';
import SyncIcon from '@material-ui/icons/Sync';
import MatchesIcon from '@material-ui/icons/Event';
import HistoryIcon from '@material-ui/icons/History';




import { makeStylesGlobal } from "../../themes/GlobalTheme"


const useStyles = makeStylesGlobal((theme) => ({

    AlignedItemIcon : {
        marginLeft: theme.spacing(1),
        marginRight: -theme.spacing(1)
    }

}))


const MenuItem = (title, icon, path) => {
    const classes = useStyles();

    const effectivePath = "/admin/" + path;
    const isActive = document.location.pathname.startsWith(effectivePath);
    return (
        <ListItem
            button
            key={title}
            component={RouterLink}
            to={effectivePath}
            selected={isActive}
        >
            <ListItemIcon className={classes.AlignedItemIcon}
            
            >{icon}</ListItemIcon>
            <ListItemText primary={title} />
        </ListItem>
    );
};

const AdminMenu = () => {
    return (
        <>
            <List>
                {MenuItem("Users", <UsersIcon />, "users")}
                {MenuItem("Demands", <DemandsIcon />, "demands")}
                {MenuItem("Matches", <MatchesIcon/>, "matches")}
                {MenuItem("Clubs", <ClubsIcon/>, "clubs")}
                {MenuItem("Fields", <FieldsIcon/>, "fields")}
                {MenuItem("Categories", <CategoriesIcon />, "categories")}
                {MenuItem("Log", <HistoryIcon/> , "log")}
                {MenuItem("Sportlink", <SyncIcon/> , "syncevents")}
            </List>
        </>
    )
}
export default AdminMenu;