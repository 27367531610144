import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Users from "../components/Admin/Users";
import Demands from "../components/Admin/Demands";
import Clubs from "../components/Admin/Clubs";
import Fields from "../components/Admin/Fields";
import Categories from "../components/Admin/Categories";
import SyncEvents from "../components/Admin/SyncEvents";
import Matches from "../components/Admin/Matches";
import Logs from "../components/Admin/Logs";

export default function AdminRouter() {
    return (
        <Switch>
            <Route path="/admin/demands" component={Demands} />
            <Route path="/admin/users" component={Users} />
            <Route path="/admin/clubs" component={Clubs} />
            <Route path="/admin/fields" component={Fields} />
            <Route path="/admin/categories" component={Categories} />
            <Route path="/admin/syncevents" component={SyncEvents} />
            <Route path="/admin/matches" component={Matches} />
            <Route path="/admin/log" component={Logs} />


            <Redirect exact from="/admin" to="/admin/users" />
        </Switch>
    )
}