import { createMuiTheme, makeStyles } from "@material-ui/core/styles";

require('typeface-lato')

export const GlobalTheme = createMuiTheme({
  
  palette: {
    primary: {
      main: "rgba(62, 108, 158, 1)", 
      light: "rgba(51, 115, 198, 0.5)",
      text: "#F7F7F7",
      mainGradient: "linear-gradient(180deg, rgba(51, 115, 198, 1) 40%, rgba(79, 150, 247, 1) 90%)",
      contrastText: "#F7F7F7"
    },

    secondary: {
      main: "rgba(62, 108, 158, 1)",
      mainGradient: "linear-gradient(180deg, rgba(242, 101, 101, 0.5) 40%, rgba(242, 101, 101, 0.9) 90%)",
    },

    default: {
      main: "rgba(48,50,62, 0.7)",
    }
  },

  typography: {
    fontFamily: [
      'Lato'
    ]
  },

  overrides: {

    MuiPaper : {
      root : {
        backgroundColor: "rgba(245, 245, 245, 1)",
      }
    },

  },
  
});


const GlobalStylesAppend = (newStyle,theme) => ({
  
  GoBackgroundColor: {
    background: "rgba(62, 108, 158,1)"
  },
  GoTextFieldInputHuge : {
    fontSize: "150%",
    backgroundColor: theme.palette.primary.light
  },



  //  self positioning boxes ||  UI components 

  RightEndAlign : {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
  },

  ScrollableModalPaper : {
    display: "block",
    overflowY: "auto",
    height: "90%",
  },

  VComponentMargin: {
    margin: '8px 8px 8px 8px',
    //padding: '4px 4px 4px 4px',
  },

  EditPanelSpacing: {
    padding: "8px 8px 8px 8px", // 
  },


  ...newStyle(theme)
});


export const makeStylesGlobal = newStyle => makeStyles(theme => GlobalStylesAppend(newStyle,theme));
