import "babel-polyfill";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import { createStateSyncMiddleware, withReduxStateSync } from 'redux-state-sync';
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk';
import rootReducer from "../reducers";

//
import {
  setLocale,
  loadTranslations,
  syncTranslationWithStore,
} from "react-redux-i18n";

import translations from "../../l10n/translations"
//

const persistConfig = { 
    key: 'gonestor',
    storage,
    blacklist : ['i18n/translations']
  }

const syncConfig = { 
  blacklist: [
    'persist/PERSIST', 'persist/REHYDRATE', 
    '@@i18n/LOAD_TRANSLATIONS'
  ],
  channel: 'gonestor_state_sync',
}

const middlewares = [thunk,createStateSyncMiddleware(syncConfig)];

const persistedReducer = persistReducer(persistConfig, withReduxStateSync(rootReducer));

export const store = createStore(persistedReducer, compose(
    applyMiddleware(...middlewares),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f)
);


//
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale("en"));
//


export const persistor = persistStore(store);
