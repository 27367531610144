import React from "react";
import format from 'date-fns/format';
import { thumbnailImagePath } from "../../js/constants/miscellaneous_cst"
import { GoNestorWhistleLogo } from "../Widgets/CustomLayoutAndSurfaces"
import { Chip } from "@material-ui/core";
import { red } from "@material-ui/core/colors";


// Date & Time 

export function DateTimeHourRenderer(maxWidth, value) {
    let content = (value)?format(new Date(value+'Z'), 'dd/MM/yyyy HH:mm'): "N/A" 
    return (
    <div style={{ width: maxWidth, whiteSpace: "nowrap" }}>
        {content}
    </div>
    );
}


// Pictures display

export function AvatarThumbnail(maxWidth, imgName) {
    const image = imgName ? `url(${thumbnailImagePath}${imgName})` : ''
    return (
        <>
            {imgName !== "00000000-0000-0000-0000-000000000000" ?
                <div style={{
                    width: maxWidth, height: maxWidth,
                    backgroundImage: image,
                    backgroundRepeat: 'no-repeat', backgroundSize: "cover",
                    backgroundPosition: "center center",
                }}>
                </div>
            :
                <div style={{margin: "16px" }}>
                    <GoNestorWhistleLogo  withBackground={true} width={maxWidth}/>
                </div>
            }
        </>
    );
}

// Status and Chips

export const StatusRenderer = (rowStatus, statusArray) => {
    for (let i = 0; i < statusArray.length; i++) {
    const element = statusArray[i];
    if (element.value === rowStatus) {
        return (
        <>
        <Chip style={{ color: "white", background: element.color }} label={element.label} />
        </>)
    }
    }
}


// Boolean rendering 

export function boolRender(rowField) {
    if (rowField) {
        return "Yes"
    } else {return "No"}
}

export function refereeRender(rowIsReferee,rowIsClubOfficial,rowIsIndoorReferee,rowIsOutdoorReferee,rowIsNationalReferee) {
    var res = "";
    if (rowIsReferee) {
        res = res + "R"
    } else res = res + " ";
    if (rowIsClubOfficial) {
        res = res + "C"
    } else res = res + " ";
    if (rowIsIndoorReferee) {
        res = res + "O"
    } else res = res + " ";
    if (rowIsOutdoorReferee) {
        res = res + "I"
    } else res = res + " ";
    if (rowIsNationalReferee) {
        res = res + "N"
    } else res = res + " ";
    return (
        <>
        {rowIsReferee === true ?
        <Chip style={{ color: "white", background: "Gray", fontFamily:"monospace" }} size="small" label="R" />
        :
        <Chip style={{ color: "black", background: "transparent", fontFamily:"monospace" }} variant="outlined" size="small" label="R" />
        }
        &nbsp;    
        {rowIsClubOfficial === true ?
        <Chip style={{ color: "white", background: "red", fontFamily:"monospace" }} size="small" label="C" />
        :
        <Chip style={{ color: "black", background: "transparent", fontFamily:"monospace" }} variant="outlined" size="small" label="C" />
        }
        &nbsp;    
        {rowIsIndoorReferee === true ?
        <Chip style={{ color: "white", background: "blue", fontFamily:"monospace"  }} size="small" label="I" />
        :
        <Chip style={{ color: "black", background: "transparent", fontFamily:"monospace" }} variant="outlined" size="small" label="I" />
        }    
        &nbsp;    
        {rowIsOutdoorReferee === true ?
        <Chip style={{ color: "white", background: "green", fontFamily:"monospace" }} size="small" label="O" />
        :
        <Chip style={{ color: "black", background: "transparent", fontFamily:"monospace" }} variant="outlined" size="small" label="O" />
        }    
        &nbsp;    
        {rowIsNationalReferee === true ?
        <Chip style={{ color: "white", background: "black", fontFamily:"monospace" }} size="small" label="N" />
        :
        <Chip style={{ color: "black", background: "transparent", fontFamily:"monospace" }} variant="outlined" size="small" label="N" />
        }    
        </>
    )

}