import * as actionTypes from "../constants/action_types";

/* eslint no-mixed-operators: 0 */
function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export const tabID = uuidv4();

const tabalive = (id) => ({
  type: actionTypes.TABALIVE,
  payload: id
});

export const updateTab = () => {
  return dispatch => {
    dispatch(tabalive(tabID));
  };
};







