import React, { useState } from 'react'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useSelector } from "react-redux"; 
import { editModel, addNewModel } from "../../js/utils/backend";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { CLUBURL } from "../../js/constants/model_cst"; 
import { useForm, FormContext } from 'react-hook-form';
import { VTextField, GoButtonGroup } from '../Widgets/CustomInputs';

const localStyle = makeStylesGlobal(theme => ({

}));


const ClubEditPanel = ({Data, onClose, isNew}) => { // to give in Club Table !

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: Data
    });


    const {
        handleSubmit
    } = methods; 

    const localStyle = makeStylesGlobal(theme => ({}));
    const classes = localStyle()

    const [formHasChanged, setFormHasChanged] = useState(false);

    const token = useSelector(state => state.auth.token);

    const submitUpdate = data => {
        const newData = {...Data, ...data };
        
        if(isNew)
        {
            addNewModel(newData, token, CLUBURL)
            .then(onClose)
        }
        else {
            editModel(newData, Data.ID, token, CLUBURL)
            .then(onClose)
        }
    }

    return (
        <FormContext {...methods}>
            <form onSubmit={handleSubmit(submitUpdate)} onChange={s => { setFormHasChanged(true) }}>
                <Grid container >
                    <Grid item sm={6} xs={12}>
                        <VTextField
                            fieldName="Name"        
                            label="Name"
                            validation={{ required: true }}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}> 
                        <VTextField
                            fieldName="Code"
                            label="Code"
                            validation={{ required: true }}
                            fullWidth={true}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {formHasChanged || isNew ?
                            <Box className={classes.RightEndAlign}>
                                <GoButtonGroup
                                    secondaryLabel="Cancel"
                                    secondaryType="button"
                                    secondaryOnClick={onClose}
                                    primaryLabel="Save"
                                    primaryType="submit"
                                />
                            </Box>
                            :
                            <></>
                        }
                    </Grid>



                </Grid>
            </form>
        </FormContext>
    )

}

export default ClubEditPanel