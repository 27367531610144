import React, { useRef, useState } from "react";
import { Grid, TablePagination } from "@material-ui/core";
import { useSelector } from "react-redux";
import MaterialTable, { MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";
import { makeStylesGlobal } from "../../themes/GlobalTheme"
import { fetchData } from "../../js/utils/backend"
import * as cst from "../../js/constants/miscellaneous_cst"
import { GoAdminToolbar } from "../Widgets/CustomLayoutAndSurfaces"
import { useReduxPrefsState } from "../../js/actions/prefs"
import { boolRender, DateTimeHourRenderer, AvatarThumbnail } from "../Widgets/TableEditors";

var I18n = require('react-redux-i18n').I18n;


const localStyle = makeStylesGlobal(theme => ({

}));

const ClubPrestations = (props) => {

    const [isTableEditing, setIsTableEditing] = useState(false);

    const token = useSelector(state => state.auth.token);
    const classes = localStyle();
    const tableRef = useRef();

    const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);

    return (
        <>
        <Grid container>
            <Grid item xs={12}>
                <MaterialTable
                    tableRef={tableRef}
                    title={I18n.t("view.teamMenu.clubPrestations")}
                    localization={I18n.t("materialTable")}
                    columns={[
                        {
                            title: `${I18n.t("materialTable.header.demandOwner")}`,
                            field: "LastName",
                            sorting: false,
                            render: rowData => rowData.OriginalOwner.LastName
                        },
                        {
                            title: `${I18n.t("materialTable.header.demandProvider")}`,
                            field: "LastName",
                            sorting: false,
                            render: rowData => {
                                const provider = rowData.OriginalProviders && (rowData.OriginalProviders.length === 1) && rowData.OriginalProviders[0].OriginalOwner.LastName //&& console.log("got it")//.OriginalOwner.LastName//[0]['OriginalOwner']['LastName'] //: null 
                                return provider
                            } 
                        },
                        {
                            title: `${I18n.t("materialTable.header.field")}`,
                            field: "Field",
                            sorting: false,
                        },
                        {
                            title: `${I18n.t("materialTable.header.gameTime")}`,
                            field: "GameTime",
                            defaultSort: "desc",
                            render: rowData => DateTimeHourRenderer(150, rowData.GameTime)
                        },
                        {
                            title: `${I18n.t("materialTable.header.categoryName")}`,
                            field: "CategoryName",
                            sorting: false,
                        },
                        {
                            title: "Type",
                            field: "TypeName",
                            sorting: false,
                        },
                    ]}
                    data={ query => fetchData(token, query, cst.clubPrestationsPath) }
                    options={{
                        pageSize: rows,
                        pageSizeOptions: cst.itemsPerPage,
                        actionsColumnIndex: 50,
                        draggable: false,
                        emptyRowsWhenPaging:false,
                        detailPanelType: 'single',
                        sorting: true,
                        search: true,
                    }}
                    onChangeRowsPerPage={(nbRows)=>setRows(nbRows)}
                    components={{
                        EditRow: props => { setIsTableEditing(true); return (<MTableEditRow {...props} />)},
                        Row: props => { setIsTableEditing(true); return (<MTableBodyRow {...props} />)},
                        Pagination: props=>(<TablePagination {...props} className={isTableEditing ?classes.disabled:null} />) ,
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} /> 
                                <GoAdminToolbar>
                                </GoAdminToolbar>
                            </div>
                        )
                    }}
                />
            </Grid>  
        </Grid>
        
        </>
    )
}

export default ClubPrestations;