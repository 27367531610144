import React, { useState } from 'react'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useSelector } from "react-redux"; 
import { editModel, addNewModel } from "../../js/utils/backend";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { CATEGORYURL } from "../../js/constants/model_cst"; 
import { useForm, FormContext } from 'react-hook-form';
import { VTextField, GoButtonGroup, VCheckBox } from '../Widgets/CustomInputs';

const localStyle = makeStylesGlobal(theme => ({

}));

const ClubEditPanel = ({Data, onClose, isNew}) => {

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: Data
    });

    const {
        handleSubmit
    } = methods; 

    const localStyle = makeStylesGlobal(theme => ({}));
    const classes = localStyle()

    const [formHasChanged, setFormHasChanged] = useState(false);

    const token = useSelector(state => state.auth.token);

    const submitUpdate = data => {
        const newData = {...Data, ...data };

        if(isNew)
        {
            addNewModel(newData, token, CATEGORYURL)
            .then(onClose)
        }
        else {
            editModel(newData, Data.ID, token, CATEGORYURL)
            .then(onClose)
        }
    }

    return (
        <FormContext {...methods}>
            <form onSubmit={handleSubmit(submitUpdate)} onChange={s => { setFormHasChanged(true) }}>
                <Grid container >
                    <Grid item sm={4} xs={12}>
                        <VTextField
                            fieldName="NameEN"        
                            label="NameEN"
                            validation={{ required: true }}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <VTextField
                            fieldName="NameFR"        
                            label="NameFR"
                            validation={{ required: true }}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <VTextField
                            fieldName="NameNL"        
                            label="NameNL"
                            validation={{ required: true }}
                            fullWidth={true}
                        />
                    </Grid>

                    <Grid item sm={3} xs={12}>
                        <VCheckBox
                            fieldName="Outdoor"
                            label="Outdoor"
                        />
                    </Grid>

                    <Grid item sm={3} xs={12}>
                        <VCheckBox
                            fieldName="Indoor"
                            label="Indoor"
                        />
                    </Grid>

                    <Grid item sm={3} xs={12}>
                        <VCheckBox
                            fieldName="Men"
                            label="Men"
                        />
                    </Grid>

                    <Grid item sm={3} xs={12}>
                        <VCheckBox
                            fieldName="Ladies"
                            label="Ladies"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {formHasChanged || isNew ?
                            <Box className={classes.RightEndAlign}>
                                <GoButtonGroup
                                    secondaryLabel="Cancel"
                                    secondaryType="button"
                                    secondaryOnClick={onClose}
                                    primaryLabel="Save"
                                    primaryType="submit"
                                />
                            </Box>
                            :
                            <></>
                        }
                    </Grid>



                </Grid>
            </form>
        </FormContext>
    )

}

export default ClubEditPanel