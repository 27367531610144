import React from 'react'
import { ThemeProvider } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";


import { GlobalTheme, makeStylesGlobal } from "../themes/GlobalTheme";
import Footer from "./Footer";

import UserRouter from "../routes/UserRouter";

const useStyles = makeStylesGlobal((theme) => ({
    root: {
        display: "flex"
    },
    content: {
        width: "100%",
        minHeight: "100vh",
    },
}));

const UserLayout = () => {

    const classes = useStyles();

    return (
            <ThemeProvider theme={GlobalTheme}>
                <div className={classes.root}>
                    <Box style={{width: "100%"}}>
                        <main className={classes.content}>
                            <UserRouter/>
                        </main>
                        <Footer/>
                    </Box>
                </div>
            </ThemeProvider>
    )
}

export default UserLayout;