import React, { useState, useEffect } from 'react'
import { useTheme, ThemeProvider } from "@material-ui/core/styles";
import { AppBar, Box, CssBaseline, Toolbar, IconButton, Grid, Divider, Drawer, Typography


} from "@material-ui/core";
import clsx from 'clsx';

import { GlobalTheme, makeStylesGlobal } from "../themes/GlobalTheme";
import Footer from "./Footer";

import TeamRouter from "../routes/TeamRouter";
import { useSelector } from "react-redux";
import AccountMenu from "../components/Account/AccountMenu"
import { GoNestorLogoSimple } from "../components/Widgets/CustomLayoutAndSurfaces"


import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import TeamMenu from "../components/Team/TeamMenu"


const drawerWidth = 200;


const useStyles = makeStylesGlobal((theme) => ({
    
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        }),
    },
    Spacer: {
        flexGrow: 1
    },
    MiniSpacer: {
        minWidth: "50px"
      },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 54, 
        [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 0,
        ...theme.mixins.toolbar,
    },
    content: {
        padding: theme.spacing(1),
        width: "100%",
        minHeight: "100vh",
    },
    
    PositionedItemIcon : {
        marginLeft: theme.spacing(1)
    },
}));

const TeamLayout = ({match}) => {

    const classes = useStyles();
    const theme = useTheme();

    const clubName = useSelector((state) => state.auth.clubName)
    const [openDrawer, setOpenDrawer] = useState(false)
    const toggleDrawer = () => {
        setOpenDrawer(state => !state)
    }

    return (
            <ThemeProvider theme={GlobalTheme}>
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar 
                        position="fixed"
                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: openDrawer,
                        })}
                    >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: openDrawer,
                            })}
                        >
                        <MenuIcon/>
                        </IconButton>
                        <Grid item container alignItems="center">
                        <Grid item>
                        <GoNestorLogoSimple height={55} width={150} />
                            </Grid>
                            <Grid item className={classes.MiniSpacer}/>
                        <Grid item>
                            <Typography variant="h5">
                                    {`${clubName}`} - Club Management
                            </Typography>
                        </Grid>
                        <Grid item className={classes.Spacer}/>
                        <AccountMenu clubName={clubName} layoutType={match.path} />
                        </Grid>
                    </Toolbar>
                    </AppBar>

                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: openDrawer,
                            [classes.drawerClose]: !openDrawer,
                        })}
                        classes={{
                            paper: clsx({
                            [classes.drawerOpen]: openDrawer,
                            [classes.drawerClose]: !openDrawer,
                            }),
                        }}
                        open={openDrawer}
                    >
                    <div className={classes.toolbar}>
                        <IconButton onClick={toggleDrawer}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <TeamMenu/>
                    </Drawer>
                    <Box style={{width: "100%"}}>
                    <main className={classes.content}>
                        <Box height="100%" id="dataViewport">
                        <div className={classes.toolbar} id="toolbarViewport"></div>
                        <TeamRouter/>
                        </Box>  
                    </main>
                    <Footer layoutType={match.path}/>
                    </Box>
                </div>
            </ThemeProvider>
    )
}

export default TeamLayout;