import React, { useRef, useState } from "react";
import { Grid, TablePagination, Box, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";

import MaterialTable, { MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";
import { makeStylesGlobal } from "../../themes/GlobalTheme"

import { LOGURL } from "../../js/constants/model_cst"
import { fetchData, getDownload, excelSpreadsheetMIME, buildUrl } from "../../js/utils/backend"

import { DateTimeHourRenderer, AvatarThumbnail } from "../Widgets/TableEditors"
import { GoPrimaryButton } from "../Widgets/CustomInputs"
import * as cst from "../../js/constants/miscellaneous_cst"; 
import { useReduxPrefsState } from "../../js/actions/prefs"
import GetAppIcon from '@material-ui/icons/GetApp';
import { GoAdminToolbar } from "../Widgets/CustomLayoutAndSurfaces";


var I18n = require('react-redux-i18n').I18n;


const localStyle = makeStylesGlobal(theme => ({

}));


const Logs = (props) => {
    const token = useSelector(state => state.auth.token);
    const [exporting, setExporting] = useState(false);
    const [searchQuery, setSearchQuery] = useState(null);
    const classes = localStyle();
    const tableRef = useRef();

    const handleDownloadClick = async () => {
        setExporting(true); // Disable the button before starting the download

        try {
            let url = buildUrl(cst.logExportToExcel,searchQuery);
            await getDownload(
                url,
                token,
                excelSpreadsheetMIME,
               `Logs`
            );

            // After download is complete, enable the button again
            setExporting(false);
        } catch (error) {
            // Handle download errors here
            console.error('Download failed:', error);
            setExporting(false);
        }
    };

    const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);

    function refreshTable() { 
        if (tableRef && tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }

    const debounceSearch = debounce((query) => {
        setSearchQuery(query);
      }, 1000); // Adjust the debounce delay as needed (e.g., 500 milliseconds)

    function debounce(func, delay) {
        let timer;
        return function (...args) {
          clearTimeout(timer);
          timer = setTimeout(() => {
            func(...args);
          }, delay);
        };
      }

    return (
        <>
        <Grid container>
            <Grid item xs={12}>
                <MaterialTable
                    tableRef={tableRef}
                    title={"Logs"}
                        columns={[
                           
                            {
                                title: "First Name",
                                field: "Firstname",
                                editable: "never"
                            },
                            
                            {
                                title: "Last Name",
                                field: "Lastname",
                                editable: "never",
                            },
                            {
                                title: "Operation",
                                field: "Operation",
                                editable: "never",
                                defaultSort: 'asc'
                            },
                            {
                                title: "Content",
                                field: "Content",
                                editable: "never",
                                defaultSort: 'asc'
                            },
                            {
                                title: "Count",
                                field: "Count",
                                type: "integer",
                                editable: "never",
                                defaultSort: 'asc'
                            },
                            {
                                title: "Created On",
                                field: "CreatedOn",
                                editable: "never",
                                defaultSort: 'asc',
                                render: rowData => DateTimeHourRenderer(150, rowData.CreatedOn)
                            },
                            {
                                title: "Last update",
                                field: "LastUpdate",
                                editable: "never",
                                defaultSort: 'asc',
                                render: rowData => DateTimeHourRenderer(150, rowData.LastUpdate)
                            },
                    ]}
                    data={query => 
                        {   
                          return fetchData(token, query, LOGURL).finally
                          ( response =>
                            {
                                console.log(query);
                                debounceSearch(query);
                            }
                            
                          )
                        }}

                    options={{
                        pageSize: rows,
                        actionsColumnIndex: 50,
                        pageSizeOptions: cst.itemsPerPage,
                        emptyRowsWhenPaging:false,
                        sorting: true,
                        search: true,
                    }}
                    onChangeRowsPerPage={(nbRows)=>setRows(nbRows)}
                    components={{
                        Row: props => {  return (<MTableBodyRow {...props} />)},
                        Pagination: props=>(<TablePagination {...props} />) ,
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} />
                                <GoAdminToolbar>
                                <GoPrimaryButton type="button"  startIcon={<GetAppIcon />}
                                    disabled={exporting}
                                    onClick={handleDownloadClick}>Export to Excel

                                </GoPrimaryButton>
                                &nbsp;
                                {exporting ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                </GoAdminToolbar>

                            </div>
                        )
                    }}
                />
            </Grid>
        </Grid>
        </>
    )
}

export default Logs;