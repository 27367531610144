import React, {useState, useEffect} from 'react';
import { 
    TextField, Button, InputAdornment, IconButton, Switch, Checkbox,
    FormControl, InputLabel, MenuItem, FormHelperText, Select, ButtonGroup, 
    FormGroup, FormControlLabel, Box, Typography
} from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStylesGlobal } from "../../themes/GlobalTheme"
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import LanguageIcon from '@material-ui/icons/Language';
import CloseIcon from '@material-ui/icons/Close';
import { useFormContext, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import frLocale from "date-fns/locale/fr";
import nlLocale from "date-fns/locale/nl";
import enLocale from "date-fns/locale/en-US";

import {I18n, setLocale} from "react-redux-i18n";

import Rating from '@material-ui/lab/Rating'; // be aware that's a mui lab component



const localStyle = makeStylesGlobal(theme => ({
    GoPrimaryButton: {
        color: theme.palette.primary.contrastText,
        background: theme.palette.primary.main,
        textTransform: "uppercase",
        borderRadius: "0px",
    },

    GoSecondaryButton : {
        borderRadius: "0px",
    },

    CloseIconButton: {
        backgroundColor: `rgba(255, 255, 255, 0.4)`, 
        '&:hover': {
            backgroundColor: `rgba(255, 255, 255, 0.7)`
        },
        display: "block", 
        margin: "8px auto", 
    },

    whiteSelector: {
        color: "white"
    }

}));



export const VTextField = ({
    label,
    fieldName,
    validation,
    ...props // any of following: InputProps, fullwidth,
}) => {
    const methods = useFormContext();
    const { errors, control } = methods;

    const classes = localStyle();


    const isError = errors[fieldName] ? true : false;
    const errorMessage = isError ? errors[fieldName].message : "";

    return (
        <div className={classes.VComponentMargin}>
            <Controller
                control={control}
                name={fieldName}
                rules={validation}
                as={
                    <TextField label={label} error={isError} helperText={errorMessage}/>
                }
                {...props}
            />
        </div>
    )
}

export const VCheckBox = ({ fieldName, label }) => {

    const methods = useFormContext();
    const {
    errors, watch, register, setValue
    } = methods;

    const localStyle = makeStylesGlobal((theme) => ({
    LabelVCheckBox: {
        marginTop: "10px", 
        marginLeft: "-16px", 
        cursor: "pointer",
    },
    LabelAndCheckBoxGroup: {
        flexDirection: "row", 
        flexWrap: "nowrap"
    }
    }));

    const classes = localStyle();
    const isError = errors[fieldName] ? true : false;
    const errorMessage = isError ? errors[fieldName].message : "";

    const watchValue = watch(fieldName)
    const value = watchValue !== undefined ? watchValue : false;

    useEffect(() => {
        register({ name: fieldName });
    }, [register, fieldName]);

    const handleChange = (event) => {
        setValue(fieldName, event.target.checked)
    };

    return (
    <div 
        className={classes.VComponentMargin}
    >
        <FormControl error={isError}>
        <FormGroup className={classes.LabelAndCheckBoxGroup}> 
            <FormControlLabel
            control={
                <Checkbox
                    name={fieldName} 
                    color="primary" 
                    onChange={handleChange} 
                    value={value}
                    checked={value} 
                />
            }
            />
            <div className={classes.LabelVCheckBox}>{label}</div>
        </FormGroup>
        <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    </div>
    );
};

export const VSelectInput = ({
    label,
    fieldName,
    validation,
    allValues,
    onClose,
    readOnly
}) => {

    const methods = useFormContext();
    const { errors, control } = methods;

    const classes = localStyle();


    const isError = errors[fieldName] ? true : false;

    const errorMessage = isError ? errors[fieldName].message : "";
    
    return (
        <div className={classes.VComponentMargin}>
            <FormControl error={isError} style={{ fullWidth: true, width: "100%" }}>
                <InputLabel>{label}</InputLabel>
                <Controller
                    name={fieldName}
                    control={control}
                    rules={validation}
                    onClose={onClose}
                    as={
                        <Select 
                            label={label}
                            inputProps={{readOnly: readOnly ? readOnly : false}}
                        >
                            {allValues && allValues.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                }
                />
                <FormHelperText hidden={!isError}>{errorMessage}</FormHelperText>
            </FormControl>
        </div>
    );
} 


export const VPhoneNumber = ({fieldName, label, validation, helperText}) => {

    const methods = useFormContext();
    const { watch, register, setValue, errors } = methods;
    const classes = localStyle();


    useEffect(() => {
        register({ name: fieldName }, validation);
    }, [register, fieldName, validation]);

    const isError = errors[fieldName] ? true : false;

    return (
    <div className={classes.VComponentMargin}>
        <InputLabel error={isError} shrink={true}>
            {label}
        </InputLabel>
        <MuiPhoneNumber
            defaultCountry={'be'}
            value={watch(fieldName)}
            autoFormat={false}
            error={isError}
            countryCodeEditable={true}
            fullWidth={true}
            onChange={(value) => {
                setValue(fieldName, value, true);
            }}
        />
        <FormHelperText error={isError}>{helperText}</FormHelperText>
    </div>
    )
}


export const VDateTimePicker = ({ fieldName, label, validation, openTo, isFilled, onClose }) => {
    const methods = useFormContext();
    const { register, errors, watch, setValue } = methods;

    let value = watch(fieldName);

    const locale = useSelector(state => state.i18n.locale);
    const localeMap = {
        en: enLocale,
        fr: frLocale,
        nl: nlLocale,
      };

    
    function defaultDate() {
        let todayDate = new Date();
        let year = todayDate.getFullYear();
        let month = (1 + todayDate.getMonth()).toString().padStart(2, "0");
        let day = todayDate.getDate().toString().padStart(2, "0");
        let date = `${year}-${month}-${day}T00:00`;
        value = date;
        return setValue(fieldName, value, true);
    }

    function prefill () {
        if (isFilled === true) {
            return defaultDate()
        } else {
            return null
        }
    }

    useEffect(() => {
        register({ name: fieldName }, validation);
    }, [register, fieldName, validation]);

    const isError = errors[fieldName] ? true : false;
    const errorMessage = isError ? errors[fieldName].message : "";
    const classes = localStyle();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
            <div className={classes.VComponentMargin}>
                <DateTimePicker
                    label={label}
                    error={isError}
                    disableFuture
                    openTo={openTo}
                    value={value ? value : prefill()}
                    onChange={(e) => {
                        let year = e.getFullYear();
                        let month = (1 + e.getMonth()).toString().padStart(2, "0");
                        let day = e.getDate().toString().padStart(2, "0");
                        let date = `${year}-${month}-${day}T00:00`;
                        setValue(fieldName, date, true);
                        if (onClose) {onClose()}
                    }}
                    fullWidth={true}
                    ampm={false}
                    views={["year", "month", "date", "hours","minutes"]}
                    format={"dd/MM/yyyy hh:mm:ss"}
                    cancelLabel={I18n.t("common.misc.cancel")}
                />
                {isError ? (
                    <FormHelperText error={isError}>{errorMessage}</FormHelperText>
                ) : null}
            </div>
        </MuiPickersUtilsProvider>
    );
};


export const VDatePicker = ({ fieldName, label, validation, openTo, isFilled, onClose }) => {
    const methods = useFormContext();
    const { register, errors, watch, setValue } = methods;

    let value = watch(fieldName);

    const locale = useSelector(state => state.i18n.locale);
    const localeMap = {
        en: enLocale,
        fr: frLocale,
        nl: nlLocale,
      };

    
    function defaultDate() {
        let todayDate = new Date();
        let year = todayDate.getFullYear();
        let month = (1 + todayDate.getMonth()).toString().padStart(2, "0");
        let day = todayDate.getDate().toString().padStart(2, "0");
        let date = `${year}-${month}-${day}T00:00`;
        value = date;
        return setValue(fieldName, value, true);
    }

    function prefill () {
        if (isFilled === true) {
            return defaultDate()
        } else {
            return null
        }
    }

    useEffect(() => {
        register({ name: fieldName }, validation);
    }, [register, fieldName, validation]);

    const isError = errors[fieldName] ? true : false;
    const errorMessage = isError ? errors[fieldName].message : "";
    const classes = localStyle();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
            <div className={classes.VComponentMargin}>
                <DatePicker
                    label={label}
                    error={isError}
                    disableFuture
                    openTo={openTo}
                    value={value ? value : prefill()}
                    onChange={(e) => {
                        let year = e.getFullYear();
                        let month = (1 + e.getMonth()).toString().padStart(2, "0");
                        let day = e.getDate().toString().padStart(2, "0");
                        let date = `${year}-${month}-${day}T00:00`;
                        setValue(fieldName, date, true);
                        if (onClose) {onClose()}
                    }}
                    fullWidth={true}
                    ampm={false}
                    views={["year", "month", "date"]}
                    format={"dd/MM/yyyy"}
                    cancelLabel={I18n.t("common.misc.cancel")}
                />
                {isError ? (
                    <FormHelperText error={isError}>{errorMessage}</FormHelperText>
                ) : null}
            </div>
        </MuiPickersUtilsProvider>
    );
};

export const VNumeric = ({
    fieldName,
    label,
    validation,
    unit,
    integer,
    ...props // onBlur, InputProps, fullwidth, type, helperText ( for mobile number), multiline
}) => {
    const convert = (value) => {
    if (!value || value === "") return null;
    return integer ? parseInt(Number(value)) : Number(value);
    };

    const cleanString = (value) => {
    if (!value || value === "") return null;
    const trimmed = value.trim();
    return integer ? parseInt(trimmed).toString() : trimmed;
    }

    const methods = useFormContext();
    const { errors, setValue, register, watch, control } = methods;
    const [stringValue, setStringValue] = useState(watch(fieldName));
    const classes = localStyle();

    const isError = errors[fieldName] ? true : false;
    const errorMessage = isError ? errors[fieldName].message : "";

    const handleChange = (event) => {
    const value = event.target.value;
    const converted = convert(value);

    if (converted === null) {
        setStringValue("");
        setValue(fieldName, null,true);
    } else {
        const finalString =
        isNaN(converted) ? stringValue : cleanString(value);
        const finalValue = convert(finalString);
        setStringValue(finalString);
        setValue(fieldName, finalValue,true);
    }
    };

    useEffect(() => {
    register({ name: fieldName }, validation);
    }, [register, fieldName, validation]);

    return (
    <div className={classes.VComponentMargin}>
        <TextField
        value={stringValue}
        error={isError}
        helperText={errorMessage}
        label={label}
        onBlur={control.onBlur}
        onFocus={control.onFocus}
        InputProps={{
            endAdornment: <InputAdornment position="end">{unit}</InputAdornment>
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{style: { textAlign: "right" }}}
        onChange={handleChange}
        {...props}
        />
    </div>
    );
};

// special inputs


export const VPasswordTextfield = ({
    label,
    fieldName,
    validation,
    ...props // any of following: InputProps, fullwidth,
}) => {
    const methods = useFormContext();
    const { errors, control } = methods;

    const isError = errors[fieldName] ? true : false;
    const errorMessage = isError ? errors[fieldName].message : "";

    return (
        <>
            <Controller
                control={control}
                name={fieldName}
                rules={validation}
                as={
                    <PasswordTextfield label={label} error={isError} helperText={errorMessage}/>
                }
                {...props}
            />
        </>
    )
}

export const PasswordTextfield = (props) => {

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    return (
        <TextField
            fullWidth={true}
            variant="outlined"
            type={showPassword ? "text" : "password"}
            InputProps={{
                endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                    type="button"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    >
                    {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon />}
                    </IconButton>
                </InputAdornment>)
            }}
            {...props}
        />
    )
}


export const VSwitcher = ({
    fieldName,
    label,
    labelPlacement,
    size,
    color,
    //data,
    //...props
}) => {

    const methods = useFormContext();
    const { control, setValue, register, watch, } = methods;

    const watchValue = watch(fieldName)
    const value = watchValue !== undefined ? watchValue : false;

    

    useEffect(() => {
        register({ name: fieldName });
    }, [register, fieldName]);

    const handleChange = (event) => {
        setValue(fieldName, event.target.checked)
    };

    const classes = localStyle();

    return (
        <div className={classes.VComponentMargin}>
            <FormControlLabel
                labelPlacement={labelPlacement ? labelPlacement : "end"}
                value={value}
                control={
                    <Switch 
                        name={fieldName} 
                        onChange={handleChange} 
                        value={value}
                        checked={value} 
                        size={size}
                    />
                }
                label={label}
            />
        </div>
    )

}


export const RatingStars = ({readOnly, value}) => {
    return (
        <>
            <Rating
                size="small"
                readOnly={readOnly}
                value={value}
            />
        </>
    )
}




// Themed BUttons


export const GoButtonGroup = ({ // label, type, onClick (if needed)
    secondaryLabel,
    secondaryType, 
    secondaryOnClick,
    primaryLabel,
    primaryType,
    primaryOnClick,
    fullWidth
}) => {
    const classes = localStyle();

    return (
    <ButtonGroup 
        disableElevation 
        fullWidth={fullWidth} 
        className={classes.VComponentMargin} 
    >
        <Button 
            className={classes.GoSecondaryButton} 
            variant="outlined" 
            color="secondary" 
            type={secondaryType}
            onClick={secondaryOnClick}
        >
        {secondaryLabel}
        </Button>
        <Button 
            className={classes.GoPrimaryButton} 
            variant="contained" 
            color="primary" 
            type={primaryType}
            onClick={primaryOnClick}
        >
        {primaryLabel}
        </Button>
    </ButtonGroup>
    )
}

export const CloseIconButton = ({onClick}) => {
    const classes = localStyle();
    
    return (
        <IconButton className={classes.CloseIconButton} onClick={onClick} >
            <CloseIcon fontSize="small" />
        </IconButton>
    )
}

export const GoPrimaryButton = (props) => {
    const classes = localStyle();

    return (
        <Button 
            variant="contained"
            color="primary"
            className={classes.GoPrimaryButton}
            {...props} 
        />
    )
}

export const GoSecondaryButton = (props) => {
    const classes = localStyle();

    return (
        <Button 
            variant="outlined"
            color="secondary"
            className={classes.GoSecondaryButton}
            {...props} 
        />
    )
}

export const GoTextButton = (props) => {

    return (
        <Button 
            {...props} 
        />
    )
}

export const LanguageSelector = ({position}) => {
    const locale = useSelector(state => state.i18n.locale);
    const dispatch = useDispatch();
    const classes = localStyle();

    const [lang, setLang] = useState();

    function changeLang (event) {
        setLang(event.target.value)
        dispatch(setLocale(event.target.value)) 
    }

    return  (
        <>
            <Box style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                <LanguageIcon fontSize="small" style={{marginRight: 4}}/>
                <Select
                    className={clsx({
                        [classes.whiteSelector] : position === "footer"
                    })}
                    disableUnderline
                    onChange={changeLang}
                    value={locale}
                >
                    <MenuItem value={"en"}>English</MenuItem>
                    <MenuItem value={"fr"}>Français</MenuItem>
                    <MenuItem value={"nl"}>Nederlands</MenuItem>

                </Select>
            </Box>
        </>
    )
}