import * as actionTypes from "../constants/action_types";
import Axios from "axios";
var I18n = require('react-redux-i18n').I18n;


export const authenticated = (validated, token, role, displayName, clubName, clubManager) => ({
  type: actionTypes.AUTHENTICATED,
  payload: { validated, token, role, displayName, clubName, clubManager}
});
export const unauthenticated = () => ({ type: actionTypes.UNAUTHENTICATED });
export const authentication_error = message => ({
  type: actionTypes.AUTHENTICATION_ERROR,
  payload: message
});
export const unhelpfultoken = () => ({ type: actionTypes.UNHELPFULTOKEN });
export const entered = (route) => ({ type: actionTypes.ENTERED, payload: route});
export const reseterror = () => ({type: actionTypes.RESETERROR});


export const newCode = (token) => {
  return dispatch => {
    var config = {
      headers: { Authorization: "Bearer " + token }
    };
    return Axios.get("/user/newcode", config)
      .then(function(response) {
        dispatch(authentication_error("New code sent."));
        }
      )
      .catch(function(error) {
        if(error.message)
        {
          dispatch(authentication_error(error.message));
        }
        else
        {
          dispatch(authentication_error("Impossible to validate code."));
        }
      });
  };
};

export const checkCode = (token, code) => {
  return dispatch => {
    var config = {
      headers: { Authorization: "Bearer " + token }
    };
    var data = {Value: code};
    return Axios.post("/user/activate", data, config)
      .then(function(response) {
        if (response.data.IsLogged === true) {
          dispatch(
            authenticated(
              response.data.IsValidated,
              response.data.Token,
              response.data.Role, 
              response.data.DisplayName,
              response.data.ClubName,
              response.data.IsClubManager
            )
          );
        } else {
          dispatch(unauthenticated());
        }
      })
      .catch(function(error) {
        if(error.response && error.response.data && error.response.data.message)
        {
          dispatch(authentication_error(error.response.data.message));
        }
        else
        {
          dispatch(authentication_error("Impossible to validate code."));
        }
      });
  };
};

export const authorize = (email, password) => {
  return dispatch => {
    return Axios.post("/user/authenticate", {
      Username: email,
      Password: password
    })
      .then(function(response) {
        if (response.data.IsLogged === true) {
          dispatch(
            authenticated(
              response.data.IsValidated,
              response.data.Token,
              response.data.Role, 
              response.data.DisplayName,
              response.data.ClubName,
              response.data.IsClubManager
            )
          );
        } else {
          dispatch(authentication_error(`${I18n.t("common.helperText.errorLoginCredentials")}`));
        }
      })
      .catch(function(error) {
        dispatch(
          authentication_error(`${I18n.t("common.helperText.errorLoginCredentials")}`)
        );
      });
  };
};

export const entry = (route) => {
  return dispatch => {
    dispatch(entered(route))
  }
};

export const tokenAuthenticated = (token) => {
  return dispatch => {
    var config = {
      headers: { Authorization: "Bearer " + token }
    };
    return Axios.get("/user/validate", config)
      .then(function(response) {
        if (response.data.IsLogged === true) {
          dispatch(
            authenticated(
              response.data.IsValidated,
              response.data.Token,
              response.data.Role,
              response.data.DisplayName,
              response.data.ClubName,
              response.data.IsClubManager
            )
          );
        } else {
          dispatch(unauthenticated());
        }
      })
      .catch(function(error) {
        if(error.response && error.response
          .status === 401)
          {
            dispatch(unauthenticated());
          }
          else
          {
            dispatch(unhelpfultoken());
          }
      });
  };
};

export const logout = () => {
  return dispatch => {
    dispatch(unauthenticated());
  }
};
