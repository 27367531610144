// All the elements to translates besides to 3 categories :
// 1) view : general pages/views with a title 
// 2) material table : this is specific to material-table package // https://material-table.com/#/docs/features/localization
// 3) common : related to fields, buttons and elements presents multiple times accross the web app 


const translations = {
    en: {
        // components
        view: {
            loginForm: {
                title: "Sign In",
                subtitle: "Don't have an account yet ?"
            },
            registerForm: {
                title: "Sign Up",
                subtitle: "Already have an account ?"
            },
            validationForm: {
                title: "Account Validation",
                clarifyUser1: "We sent a verification code to the mobile number you entered.",
                clarifyUser2: "Please enter it below to activate your account.",
            },
            clubView: {
                welcome: "Welcome",
                logged: "you are logged",
            },
            changePassword: {
                title: "Change your password",
            },
            resetPassword: {
                title: "Reset your password"
            },
            admin: {
                panel: "Panel",
                users: "Users",
                demands: "Demands"
            },
            teamMenu: {
                members: "Members",
                clubDemands: "Club Demands",
                clubPrestations: "Club Prestations"
            },
            accountMenu : {
                changePassword: "Change Password",
                administration: "Administration",
                clubManager: "Club Manager"
            },
        },
        materialTable: { 
            pagination: {
                labelDisplayedRows: '{from}-{to} of {count}',
                labelRowsSelect: 'rows',
            },
            toolbar: {
                nRowsSelected: '{0} row(s) selected',
                searchTooltip: 'Search',
                searchPlaceholder: 'Search'
            },
            header: {
                // Club Manager: 
                // Members
                actions: 'Actions',
                firstName: 'First Name',
                lastName: 'Last Name',
                indoorReferee: 'Indoor Umpire',
                outdoorReferee: 'Outdoor Umpire',
                // Demands 
                demandOwner: "Demand Owner",
                demandProvider: "Demand Provider",
                field: 'Field',
                gameTime: 'Game Time',
                categoryName: 'Category Name',
                isPaid: 'Is Paid'
                // Prestations

            },
            body: {
                emptyDataSourceMessage: 'No records to display',
                filterRow: {
                    filterTooltip: 'Filter'
                }
            }
        },
        // common
        common: {
            fields: {
                address: "Address",
                email: "E-mail",
                confirmEmail: "Confirm E-Mail",
                password: "Password",
                confirmPassword: "Confirm Password",
                confirmNewPassword: "Confirm New Password",
                oldPassword: "Old Password",
                firstname: "First Name",
                lastname: "Last Name",
                gender: "Gender",
                mobile: "Mobile Number",
                avatar: "Avatar",
                birthDate: "Birth Date",
                language: "Language",
                postalCode: "Postal Code",
                city: "City",
                country: "Country",
                range: "Range",
                date: "Date",
                category: "Category",
                role: "Role",
            },
            button: {
                createAccount: "Create Account",
                signIn: "Sign In",
                capture: "Capture",
                upload: "Upload",
                cancel: "Cancel",
                update: "Update",
                activate: "Activate",
                updateResendCode: "Update and resend code",
                resend: "Resend",
                changeNumber: "Change Number",
                logout: "Logout",
                changePassword: "Change my password",
                sendEmail: "Send email",
            },
            tooltip: {
                showProfile: "Show Profile",
                edit: "Edit"
            },
            helperText: {
                cameraNotFound: "We can't find your camera or webcam.",
                cameraTip: "Check to be sure it's connected and enabled, and that its access is allowed in your browser.",
                dropzoneTip: "Drag & drop files here or click to browse",
                dropzoneDrop: "Drop file here",
                dropzoneFileNotAllowed: "Sorry, file type not accepted (only allowed :",
                // for inputs : 
                emailInvalid: "Please enter a valid e-mail, e.g. email@email.com",
                emailNotMatching: "E-mail confirmation is not matching",
                passwordInvalid: "Use a minimum password length of 5 or more characters",
                passwordNotMatching: "Password confirmation is not matching",
                mobileInvalid: "Please enter a valid phone number",
                // errors : 
                errorPasswordReset: "Impossible to reset password now, please try again later.",
                errorPasswordChange: "Impossible to change password now, please try again later.",
                errorProfileFetch: "Impossible to fetch you profile account, please try again later.",
                errorLoginCredentials: "You have entered an invalid username or password"
            },
            links: {
                forgotPassword: "Forgot Password ?",
                signUp: "Sign up now",
                signIn: "Sign in",
                backLogin: "Go back to login"
            },
            misc: {
                allRightsReserved: "All Rights Reserved",
                or: "Or",
                cancel: "Cancel"
            }
        }
    },
    nl: {
        // components
        view: {
            loginForm: {
                title: "Inloggen",
                subtitle: "Nog geen account ?"
            },
            registerForm: {
                title: "Inschrijven",
                subtitle: "Heb je al een account ?"
            },
            validationForm: {
                title: "Account validatie",
                clarifyUser1: "We hebben een verificatiecode gestuurd naar het mobiele nummer dat u heeft ingevoerd.",
                clarifyUser2: "Voer het hieronder in om uw account te activeren.",
            },
            clubView: {
                welcome: "Welcome",
                logged: "you are logged",
            },
            changePassword: {
                title: "Change your password",
            },
            resetPassword: {
                title: "Stel je wachtwoord opnieuw in"
            },
            admin: {
                panel: "Paneel",
                users: "Users",
                demands: "Demands"
            },
            teamMenu: {
                members: "Leden",
                clubDemands: "Aanvragen",
                clubPrestations: "Prestaties"
            },
            accountMenu : {
                changePassword: "Verander wachtwoord",
                administration: "Beheerpaneel",
                clubManager: "Club Manager"
            }
        },
        materialTable: {
            pagination: {
                labelDisplayedRows: '{from}-{to} van {count}',
                labelRowsSelect: 'reeks',
            },
            toolbar: {
                nRowsSelected: '{0} reek(s) geselecteerd',
                searchTooltip: 'Zoeken naar ...',
                searchPlaceholder: 'Zoeken naar ...'
            },
            header: {
                // Club Manager: 
                // Members
                actions: 'Actions',
                firstName: 'Voornaam',
                lastName: 'Achternaam',
                indoorReferee: 'Indoor Umpire',
                outdoorReferee: 'Outdoor Umpire',
                // Demands 
                demandOwner: "Aanvraager",
                demandProvider: "Dienstverlener",
                field: 'Veld',
                gameTime: 'Speeltijd',
                categoryName: 'Categorie Naam',
                isPaid: 'Is Betaald'
                // Prestations
            },
            body: {
                emptyDataSourceMessage: 'Geen records om weer te geven',
                filterRow: {
                    filterTooltip: 'Filter'
                }
            },
        },
        // common
        common: {
            fields: {
                address: "Address",
                email: "E-mail",
                confirmEmail: "Confirm E-Mail",
                password: "Wachtwoord",
                confirmPassword: "Confirm Password",
                confirmNewPassword: "Confirm New Password",
                oldPassword: "Old Password",
                firstname: "Voornaam",
                lastname: "Achternaam",
                gender: "Gender",
                mobile: "Mobiel nummer",
                avatar: "Avatar",
                birthDate: "Geboortedatum",
                language: "Taal",
                postalCode: "Postcode",
                city: "Stad",
                country: "Land",
                range: "Range",
                date: "Date",
                category: "Category",
                role: "Role"
            },
            button: {
                createAccount: "Maak een account aan",
                signIn: "Aanmelden",
                capture: "Maak een foto",
                upload: "Uploaden",
                cancel: "Cancel",
                update: "Update",
                activate: "Activeren",
                updateResendCode: "Bijwerken code nogmaals versturen",
                resend: "Resend",
                changeNumber: "Verander GSM",
                logout: "Uitloggen",
                changePassword: "Change my password",
                sendEmail: "E-mail verzenden",
            },
            tooltip: {
                showProfile: "Show Profile",
                edit: "Edit"
            },
            helperText: {
                cameraNotFound: "We kunnen je camera of webcam niet vinden.",
                cameraTip: "Controleer of het verbonden en ingeschakeld is, en of de toegang toegestaan is in uw browser.",
                dropzoneTip: "Sleep bestanden hier naartoe of klik om te bladeren",
                dropzoneDrop: "Zet het bestand hier neer",
                dropzoneFileNotAllowed: "Sorry, bestandstype niet geaccepteerd (Alleen toegestaan: :",
                // for inputs : 
                emailInvalid: "Gelieve een geldig e-mailadres in te vullen, bijv. email@email.com",
                emailNotMatching: "E-mailbevestiging komt niet overeen",
                passwordInvalid: "Gebruik een minimale wachtwoordlengte van 5 of meer tekens",
                passwordNotMatching: "Wachtwoordbevestiging komt niet overeen",
                mobileInvalid: "Voer alstublieft een geldig telefoonnummer in",
                // errors : 
                errorPasswordReset: "Wachtwoord kan nu niet opnieuw worden ingesteld, probeer het later opnieuw.",
                errorPasswordChange: "Wachtwoord kan nu niet worden gewijzigd, probeer het later opnieuw.",
                errorProfileFetch: "Je profielaccount kan niet worden opgehaald. Probeer het later opnieuw.",
                errorLoginCredentials: "Uw login / wachtwoord komen niet overeen."
            },
            links: {
                forgotPassword: "Wachtwoord vergeten ?",
                signUp: "Nu aanvragen",
                signIn: "Aanmelden",
                backLogin: "Ga terug om in te loggen"
            },
            misc: {
                allRightsReserved: "All Rights Reserved",
                cancel: "Annuleren"
            }
        }
    },
    fr: {
        // components
        view: {
            loginForm: {
                title: "Connexion",
                subtitle: "Vous n'avez pas encore de compte ?"
            },
            registerForm: {
                title: "S'inscrire",
                subtitle: "Vous avez déjà un compte ?"
            },
            validationForm: {
                title: "Validation du compte",
                clarifyUser1: "Un code de vérification a été envoyé au numéro mobile renseigné.", 
                clarifyUser2: "Merci de saisir le code ci-dessous pour activer votre création de compte." 
            },
            clubView: {
                welcome: "Welcome",
                logged: "you are logged",
            },
            changePassword: {
                title: "Change your password",
            },
            resetPassword: {
                title: "Réinitialisez votre mot de passe"
            },
            admin: {
                panel: "Panneau",
                users: "Users",
                demands: "Demands"
            },
            teamMenu: {
                members: "Membres",
                clubDemands: "Demandes",
                clubPrestations: "Prestations"
            },
            accountMenu : {
                changePassword: "Changer le mot de passe",
                administration: "Administration",
                clubManager: "Club Manager"
            }
        },
        materialTable: {
            pagination: {
                labelDisplayedRows: '{from}-{to} sur {count}',
                labelRowsSelect: 'lignes',
            },
            toolbar: {
                nRowsSelected: '{0} ligne(s) sélectionnées',
                searchTooltip: 'Rechercher',
                searchPlaceholder: 'Rechercher'
            },
            header: {
                // Club Manager: 
                // Members
                actions: 'Actions',
                firstName: 'Prénom',
                lastName: 'Nom',
                indoorReferee: 'Indoor Umpire',
                outdoorReferee: 'Outdoor Umpire',
                // Demands 
                demandOwner: "Demandeur",
                demandProvider: "Prestataire",
                field: 'Terrain',
                gameTime: 'Temps de jeu',
                categoryName: 'Catégorie',
                isPaid: 'Est payé'
                // Prestations
            },
            body: {
                emptyDataSourceMessage: 'Pas de données à afficher',
                filterRow: {
                    filterTooltip: 'Filtre'
                }
            }
        },
        // common
        common: {
            fields: {
                address: "Address",
                email: "E-mail",
                confirmEmail: "Confirmation E-Mail",
                password: "Mot de passe",
                confirmPassword: "Confirmation du mot de passe",
                confirmNewPassword: "Confirmation du nouvel mot de passe",
                oldPassword: "Ancien mot de passe",
                firstname: "Prénom",
                lastname: "Nom",
                gender: "Genre",
                mobile: "Numéro de portable",
                avatar: "Avatar",
                birthDate: "Date de naissance",
                language: "Langue",
                postalCode: "Code postal",
                city: "Ville",
                country: "Pays",
                range: "Distance",
                date: "Date",
                category: "Category",
                role: "Role"
            },
            button: {
                createAccount: "Créer le compte",
                signIn: "Connexion",
                capture: "Prendre une photo",
                upload: "Télécharger",
                cancel: "Cancel",
                update: "Update",
                activate: "Activer",
                updateResendCode: "Mettre à jour et renvoyer le code",
                resend: "Renvoyer",
                changeNumber: "Changer GSM",
                logout: "Déconnexion",
                changePassword: "Change my password",
                sendEmail: "Envoyer l'email",
            },
            tooltip: {
                showProfile: "Show Profile",
                edit: "Edit"
            },
            helperText: {
                cameraNotFound: "La caméra ou la webcam est introuvable",
                cameraTip: "Vérifiez qu'elle est connectée et disponible, et que l'accès est autorisé dans votre navigateur",//"Check to be sure it's connected and enabled, and that its access is allowed in your browser.",
                dropzoneTip: "Glisser déposer votre fichier ici, ou cliquez pour parcourir sur votre appareil",//"Drag & drop files here or click to browse",
                dropzoneDrop: "Déposer votre fichier ici",
                dropzoneFileNotAllowed: "Désolé, ce type de fichier n'est pas accepté (seulement approuvé:",//"Sorry, file type not accepted (only allowed :",
                // for inputs : 
                emailInvalid: "Veuillez saisir un e-mail valide, par exemple : email@email.com",//"Please enter a valid e-mail, e.g. email@email.com",
                emailNotMatching: "La confirmation e-mail ne correspond pas",//"E-mail confirmation is not matching",
                passwordInvalid: "Veuillez utiliser un mot de passe d'au moins 5 caractères",//"Use a minimum password length of 5 or more characters",
                passwordNotMatching: "La confirmation de mot de passe ne correspond pas",//"Password confirmation is not matching",
                mobileInvalid: "Veuillez saisir une numéro de mobile valide",//"Please enter a valid phone number",
                // errors : 
                errorPasswordReset: "Impossible de réinitialiser le mot de passe maintenant, veuillez réessayer plus tard", //"Impossible to reset password now, please try again later.",
                errorPasswordChange: "Impossible de changer le mot de passe maintenant, veuillez réessayer plus tard", //"Impossible to change password now, please try again later.",
                errorProfileFetch: "Impossible de récupérer votre compte de profil, veuillez réessayer plus tard", //"Impossible to fetch you profile account, please try again later.",
                errorLoginCredentials: "Le login ou le mot de passe n'est pas correct"
            },
            links: {
                forgotPassword: "Mot de passe oublié ?",
                signUp: "Créez votre compte",
                signIn: "Connexion",
                backLogin: "Revenir à l'écran de connexion" //"Go back to login"
            },
            misc: {
                allRightsReserved: "All Rights Reserved",
                cancel: "Annuler"
            }
        }
    },

};

export default translations;