import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import UserView from "../components/User/UserView"

export default function UserRouter() {
    return (
        <Switch>
            <Route exact path="/user/home" component={UserView} />

            <Redirect exact from="/user" to="/user/home"/>
        </Switch>
    )
}