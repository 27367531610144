import React, { useState, useEffect } from 'react'
import { GoSecondaryButton } from './CustomInputs';
import Dropzone from "../Widgets/Dropzone"
import {
    InputLabel, Grid, Typography, IconButton, FormHelperText, Box
} from "@material-ui/core";
import UploadIcon from '@material-ui/icons/Publish';
import CameraIcon from '@material-ui/icons/Camera';
import { useSelector } from "react-redux";
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import * as cst from "../../js/constants/miscellaneous_cst.js";
import ReplayIcon from '@material-ui/icons/Replay';
import Axios from 'axios';
import { useFormContext } from 'react-hook-form';
import { makeStylesGlobal } from "../../themes/GlobalTheme"
import {GoModal, GoInfo} from "../Widgets/CustomLayoutAndSurfaces"
import { base64toBlob } from "../../js/utils/computed"
var I18n = require('react-redux-i18n').I18n;


const localStyle = makeStylesGlobal(theme => ({
    
    ButtonsContainer: {
        display: "flex",
        height: "162px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    DisplayPhoto : {
        height: '162px',
        backgroundRepeat: 'no-repeat',
        width: "100%", 
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        border: "1px solid rgba(224, 224, 224, 0.9)",
    },
    Camera : {
        display: "flex",
        justifyContent: "center",
    },
    DropzoneIconsGroup: {
        display: "flex", 
        marginTop: "-50px", 
        justifyContent: "space-around",
        alignItems: "center",
        opacity: 0,
        '&:hover': {
            opacity: 1,
        }
    },
    IconPosition: {
        position: "relative",
        marginTop: "-64px"
    },
    IconBackground: {
        backgroundColor: `rgba(255, 255, 255, 0.4)`, 
        '&:hover': {
            backgroundColor: `rgba(230, 230, 230, 0.8)`
        },
    }
}));

const AvatarPicker = ({
    fieldName, label, validation, fileType, 
    onChange
}) => {

    const methods = useFormContext();
    const { register, setValue, errors, watch } = methods;
    const token = useSelector(state => state.auth.token);
    const classes = localStyle();

    useEffect(() => {
        register({ name: fieldName }, validation);
    }, [register, fieldName, validation]);

    const isActualGuid = (watch("Avatar") === "00000000-0000-0000-0000-000000000000") ? false : true 
    const [isAvatar, setIsAvatar] = useState( watch("Avatar") ? isActualGuid : false )

    const [guid, setGuid] = useState(watch("Avatar"));
    const url = `url(${cst.thumbnailImagePath}${guid})`

    function handleTakePhoto(dataUri) {
        let currentTime = new Date()
        let fileName = `fromCamera_${currentTime.toISOString()}`
        let url = cst.uploadImagePath + fileName
        let data = base64toBlob(dataUri) // conversion of file to raw binary data
        let config = {
            headers: { 'Content-Type': 'application/octet-stream', Authorization: "Bearer " + token }
        }
        Axios.post(url + fileName, data, config)
            .then((response) => {
                setGuid(response.data)
                setValue(fieldName, response.data, true);
                setIsAvatar(true)
            })
    }

    const [showDropzone, updateShowDropzone] = useState(false)
    const toggleDropzone = () => updateShowDropzone(state => !state)
    const onUpload = (guid) => {setGuid(guid)}


    const [showCameraModal, updateShowCameraModal] = useState(false)
    const toggleCameraModal = () => updateShowCameraModal(state => !state)
    const [isCameraAllowed, setIsCameraAllowed] = useState()//
    
    useEffect(() => {
        if(showCameraModal === true) {accessCamera()}
    }, [showCameraModal])

    function accessCamera() {
        navigator.mediaDevices.getUserMedia({video: true})
        .then(function (){setIsCameraAllowed(true)})
        .catch(function(){setIsCameraAllowed(false)})
    }

    const showUploadedPicture = isAvatar ? true : false
    const showUploadButtons = !isAvatar && !showDropzone

    const isError = errors[fieldName] ? true : false;
    const errorMessage = isError ? errors[fieldName].message : "";

    return (
        <>
            <InputLabel className={classes.VComponentMargin} error={isError} shrink={true}>
                {label}
            </InputLabel>

            <>
                {showUploadButtons && 
                    <Grid container className={classes.ButtonsContainer}>
                        <GoSecondaryButton type="button" size="large" onClick={() => toggleCameraModal()} startIcon={<CameraIcon />}>
                            {I18n.t("common.button.capture")}
                        </GoSecondaryButton>
                        <Typography>{I18n.t("common.misc.or")}</Typography>
                        <GoSecondaryButton type="button" size="large" onClick={toggleDropzone} startIcon={<UploadIcon />}>
                            {I18n.t("common.button.upload")}
                        </GoSecondaryButton>
                        {guid === undefined ? (
                            <FormHelperText error={isError}>{errorMessage}</FormHelperText>
                        ) : null}
                    </Grid>
                }

                {(showUploadedPicture || showDropzone) &&
                    <Grid container alignItems="flex-end" justify="center" className={classes.VComponentMargin} >

                        {showUploadedPicture && 
                            <div 
                                className={classes.DisplayPhoto} 
                                style={{ backgroundImage: url}}
                            />
                        }

                        {showDropzone && 
                            <Dropzone
                                isMulti={false}
                                fileType={fileType}
                                modalMode={false}
                                onChange={(guid) => {
                                    toggleDropzone();
                                    setValue(fieldName, guid, true);
                                    setIsAvatar(true)
                                    onUpload(guid)
                                }}
                            />
                        }

                        <Box className={classes.IconPosition}>
                            <IconButton 
                                className={classes.IconBackground} 
                                onClick={() => { 
                                    setIsAvatar(false); 
                                    setValue(fieldName, undefined, true);
                                    showDropzone && toggleDropzone(); 
                                    onChange && onChange("changed")
                                }}
                            >
                                <ReplayIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                }
            </>

            <GoModal showModal={showCameraModal && (isCameraAllowed !== undefined)} updateModalState={toggleCameraModal}>
                    <Box className={classes.Camera} >
                        {isCameraAllowed ? 
                            <Camera
                                onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); setValue(fieldName, guid, true); toggleCameraModal() }}
                                idealResolution={{ height: 512, width: 512 }}
                                idealFacingMode={FACING_MODES.USER} // FACING_MODES.ENVIRONMENT (main camera) FACING_MODES.USER (selfie camera)
                                isDisplayStartCameraError
                                sizeFactor= {1}
                            /> : 
                            
                            <GoInfo>
                                <Typography variant="h6" gutterBottom>
                                    {I18n.t("common.helperText.cameraNotFound")}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    {I18n.t("common.helperText.cameraTip")}
                                </Typography>
                            </GoInfo>
                        }
                    </Box>
            </GoModal>
        </>
    );
}

export default AvatarPicker;