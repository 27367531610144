var I18n = require('react-redux-i18n').I18n;


//Const variables for tables
export const defaultPageSize = 10
export const itemsPerPage = [10,50,100]
//const locale = useSelector(state => state.i18n.locale);




// VSelectInput options array 

export const genderListEN = [
    {value: "", label: ""},
    {value: "Male", label: "Male"},
    {value: "Female", label: "Female"},
]

export const genderListFR = [
    {value: "", label: ""},
    {value: "Male", label: "Masculin"},
    {value: "Female", label: "Féminin"},
]

export const genderListNL = [
    {value: "", label: ""},
    {value: "Male", label: "Heer"},
    {value: "Female", label: "Mevrouw"},
]





export const languageList = [
    {value: "", label: ""},
    {value: "en", label: "English"},
    {value: "fr", label: "Français"},
    {value: "nl", label: "Nederlands"}
]

// STATUS :

// HasDesignedProvider (referee)
export const designedProviderStatus = [
    { value: true, label: 'Success', color: "rgba(102, 201, 56, 1)" },
    { value: false, label: 'No candidates', color: "rgba(237, 38, 12)" },
]

// Is some geo data field missing ? (Address fields, is null, empty or 0 ; PostCode, City, Country, Latitude, Longitude  )
export const geodataCompletion = [
    //{value: true, label: '', color: "rgba()" },
    {value: false, label: 'Missing', color: "rgba(237, 38, 12)"}
]


// regex rules 
export const phoneNumberRegex = /^(\+)?([ 0-9]){10,16}$/;
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const passwordRegex = /^.{5,}$/;


// url for Axios requests

export const uploadImagePath = `/image/upload/`
export const thumbnailImagePath = `/Image/thumbnail/`

export const userPath = `/user/`

export const resetPasswordPath = `/user/lost`
export const adminresetPasswordPath = `/user/resetpassword`
export const userProfilePath = `/user/profile/`
export const userRefreshSportLinkPath = `/user/refreshsportlink/`
export const usersExportToExcel = `/user/ExportToExcel/`


export const demandsExportToExcel = `/demand/ExportToExcel/`
export const matchesExportToExcel = `/match/ExportToExcel/`
export const logExportToExcel = `/log/ExportToExcel/`
export const clubsExportToExcel = `/club/ExportToExcel/`
export const categoryExportToExcel = `/category/ExportToExcel/`
export const fieldsExportToExcel = `/field/ExportToExcel/`


export const clubMembersPath = `/user/club` // get members of a club
export const clubDemandsPath = `/demand/club` // get demands of the current club
export const clubPrestationsPath = `/demand/clubreferee` // get club's referee prestations

// helper messages (helper texts)

export const msgPhoneNumber = "Please enter a valid phone number"
export const msgEmailInvalid = "Please enter a valid e-mail, e.g. email@email.com"
export const msgEmailUnmatching = "E-mail confirmation is not matching"
export const msgPasswordInvalid = "Use a minimum password length of 5 or more characters"
export const msgPasswordUnmatching = "Password confirmation is not matching"

// error messages (for error catch and GoError component)


export const errorPasswordReset = "Impossible to reset password now, please try again later."
export const errorPasswordChange = "Impossible to change password now, please try again later."
export const errorProfileFetch = "Impossible to fetch you profile account, please try again later."
export const errorLoginCredentials = "You have entered an invalid username or password"
