import React, { useState } from 'react'
import Box from '@material-ui/core/Box';
import {Grid, Link} from '@material-ui/core';
import { useSelector } from "react-redux"; 
import { editModel, addNewModel } from "../../js/utils/backend";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { FIELDURL } from "../../js/constants/model_cst"; 
import { useForm, FormContext} from 'react-hook-form';
import { VTextField, VNumeric, GoButtonGroup } from '../Widgets/CustomInputs';
import MapIcon from '@material-ui/icons/Map';
import { IconButton, TextField, InputAdornment } from '@material-ui/core';
import { VClubChooser } from "../Widgets/VClubChooser";
import LocationChooser from '../Widgets/LocationChooser';

const localStyle = makeStylesGlobal(theme => ({
}));

const FieldEditPanel = ({Data, onClose, isNew}) => { 

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: Data 
    });

    const {handleSubmit, watch, setValue} = methods; 

    const localStyle = makeStylesGlobal(theme => ({}));
    const classes = localStyle()
    const token = useSelector(state => state.auth.token);

    const [formHasChanged, setFormHasChanged] = useState(false);

    const submitUpdate = data => {
        const newData = {...Data, ...data}
        if(isNew)
        {
            addNewModel(newData, token, FIELDURL)
            .then(onClose)
        }
        else {
            editModel(newData, Data.ID, token, FIELDURL)
            .then(onClose)
        }
    }

    const [long, setLong] = useState(undefined)
    const [lat, setLat] = useState(undefined)
    const [isEdited, setIsEdited] = useState(false)

    const handleSavePoint = savePoint => {
        setIsEdited(true)
        setLong(savePoint.lng)
        setLat(savePoint.lat)
        
        setValue('Longitude', savePoint.lng)
        setValue('Latitude', savePoint.lat)
    }

    return (
        <>
        <FormContext {...methods}>
            <form onSubmit={handleSubmit(submitUpdate)} onChange={s => { setFormHasChanged(true) }}>
                <Grid container>
                    <Grid item sm={4} xs={12}>
                        <VClubChooser
                            fieldName="ClubID"
                            label="Club"
                            value={Data ? Data.OriginalClub.Name : undefined}
                            validation={{ required: true }}
                        />
                    </Grid>

                    <Grid item sm={4} xs={12} >
                        <VTextField
                            fieldName="Name"        
                            label="Field Name"
                            fullWidth={true}
                            validation={{ required: true }}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12} > 
                        <VTextField
                            fieldName="Code"
                            label="Field Code"
                            fullWidth={true}
                            validation={{ required: true }}
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <VTextField
                            fieldName="Address"
                            label="Address"
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <VTextField     
                            fieldName="PostCode"
                            label="Postal Code"
                            fullWidth={true}
                            value={watch('PostCode')}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <VTextField     
                            fieldName="City"
                            label="Commune"
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <VTextField
                            fieldName="Country"
                            label="Country"
                            fullWidth={true}
                        />
                    </Grid>

                    {isEdited ? 
                        ( 
                            // after moving/drag pin on map, there will be displayed two read-only inputs, 
                            <>
                                <Grid item md={3} sm={5} xs={12}>
                                    <div className={classes.VComponentMargin}>
                                        <TextField fullWidth label="Latitude" value={lat}  
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: <InputAdornment position="end">{null}</InputAdornment>
                                            }}
                                            inputProps={{style: { textAlign: "right" }}}
                                        />
                                    </div>
                                </Grid>
                                <Grid item md={3} sm={5} xs={12}>
                                    <div className={classes.VComponentMargin}>
                                        <TextField fullWidth label="Longitude" value={long} 
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: <InputAdornment position="end">{null}</InputAdornment>
                                            }}
                                            inputProps={{style: { textAlign: "right" }}}
                                        />
                                    </div>
                                </Grid>
                            </>
                        ) 
                            : 
                        ( 
                            // to edit these, if pin was moved, user should cancel/save then reopen panel and edit manually (by not moving the pin)
                            <>
                                <Grid item md={3} sm={5} xs={12}>
                                    <VNumeric
                                        fieldName="Latitude"
                                        label="Latitude"
                                        fullWidth={true}
                                        validation={{
                                            validate: (val) => { return val < 90 && val > -90 }
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3} sm={5} xs={12}>
                                    <VNumeric
                                        fieldName="Longitude"
                                        label="Longitude"
                                        fullWidth={true}
                                        validation={{
                                            validate: (val) => { return val < 180 && val > -180 }
                                        }}
                                    />
                                </Grid>
                            </>
                        )
                    }

                    <Grid item container md={3} sm={2} xs={12} alignItems="center">
                        <Link 
                                href={`https://www.google.com/maps/search/?api=1&query=${isEdited ? lat : watch('Latitude')},${isEdited ? long : watch('Longitude')}`}
                                target="_blank" 
                        >
                            <IconButton>
                                <MapIcon/>
                            </IconButton>
                        </Link>
                    </Grid>
                    
                    <LocationChooser latitude={watch('Latitude')} longitude={watch('Longitude')} range="600" onChange={handleSavePoint} />

                    <Grid item xs={12}>
                        <Box className={classes.RightEndAlign}>
                            <GoButtonGroup
                                secondaryLabel="Cancel"
                                secondaryType="button"
                                secondaryOnClick={onClose}
                                primaryLabel="Save"
                                primaryType="submit"
                            />
                        </Box>
                    </Grid>

                </Grid>
            </form>
        </FormContext>
        </>
    )

}

export default FieldEditPanel