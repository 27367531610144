import React from "react";
import RouterLink from "../../routes/RouterLink"

import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";

import PeopleIcon from '@material-ui/icons/People';
import DemandsIcon from '@material-ui/icons/Forum';
import PrestationIcon from '@material-ui/icons/Assignment';


import { makeStylesGlobal } from "../../themes/GlobalTheme"

var I18n = require('react-redux-i18n').I18n;



const useStyles = makeStylesGlobal((theme) => ({

    AlignedItemIcon : {
        marginLeft: theme.spacing(1),
        marginRight: -theme.spacing(1)
    }

}))

const MenuItem = (title, icon, path) => {
    const classes = useStyles();

    const effectivePath = "/team/" + path;
    const isActive = document.location.pathname.startsWith(effectivePath);
    return (
        <ListItem
            button
            key={title}
            component={RouterLink}
            to={effectivePath}
            selected={isActive}
        >
            <ListItemIcon className={classes.AlignedItemIcon}
            
            >{icon}</ListItemIcon>
            <ListItemText primary={title} />
        </ListItem>
    );
};

const TeamMenu = () => {
    return (
        <>
            <List>
                {MenuItem(`${I18n.t("view.teamMenu.members")}`, <PeopleIcon/>, "members")}
                {MenuItem(`${I18n.t("view.teamMenu.clubDemands")}`, <DemandsIcon/>, "clubdemands" )}
                {MenuItem(`${I18n.t("view.teamMenu.clubPrestations")}`, <PrestationIcon/>, "clubprestations")}
                </List>
        </>
    )

}

export default TeamMenu