import React, { useState, useEffect } from 'react';

import { makeStylesGlobal } from "../../themes/GlobalTheme"
import { Typography, Grid, TextField, Button, Box } from "@material-ui/core";
import { GoPaper, GoModal, GoError } from "../Widgets/CustomLayoutAndSurfaces"
import { useSelector, useDispatch } from 'react-redux';
import { checkCode, newCode, logout, authenticated, authorize } from "../../js/actions/auth"
import { useForm, FormContext } from 'react-hook-form';
import Axios from 'axios';
import { GoPrimaryButton, GoSecondaryButton, VPhoneNumber } from '../Widgets/CustomInputs';
import * as cst from "../../js/constants/miscellaneous_cst"
import { PhoneNumberValidation } from "../../js/utils/validations"
var I18n = require('react-redux-i18n').I18n;


const useStyles = makeStylesGlobal((theme) => ({


}));

const ValidationForm = ({ match }) => {

    const token = useSelector(state => state.auth.token);
    const classes = useStyles();
    const dispatch = useDispatch();
    const error = useSelector(state => state.auth.errorMessage);


    const [userData, setUserData] = useState();

    const [showError, setShowError] = React.useState(false);
    const [submitError, setSubmitError] = useState(null);

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: { userData }
    })
    const { handleSubmit, reset } = methods;


    const [validationCode, setValidationCode] = useState("");

    useEffect(() => {
        fetchUserProfile()
    }, [])

    const onSubmit = data => {
        const updatedUserMobileNumber = {...userData, ...data}
        let config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        };
        let url = cst.userProfilePath;
        Axios.put(url, updatedUserMobileNumber, config)
        .then(response => {
            if (response.data.IsLogged === true) {
                dispatch(authenticated(
                    response.data.IsValidated,
                    response.data.Token,
                    response.data.Role
                ));
            }
            toggleNumberChangeModal()
            fetchUserProfile()
    
        })
        .catch(error => {
            if (error.response && error.response.data && error.response.data.message) {
                setSubmitError(error.response.data.message);
            } else {
                setSubmitError(cst.errorProfileFetch)
            }
        })
    }

    const handleSubmitCode = () => {
        dispatch(checkCode(token, validationCode));
        setShowError(true);
    }
    const handleGetNewCode = () => {
        dispatch(newCode(token));
        setShowError(true);
    };
    const handleLogout = () => {
        dispatch(logout());
    };

    const fetchUserProfile = () => {
        let url = `/user/profile`
        let config = {
            headers: { Authorization: `Bearer ${token}` }
        }
        Axios.get(url, config)
            .then(function (response) {
                setUserData(response.data)
                reset(response.data);
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.message) {
                setSubmitError(error.response.data.message);
                } else {
                setSubmitError(cst.errorProfileFetch)
                }
            })
    }

        const [showNumberChangeModal, updateShowNumberChangeModal] = useState(false)
        const toggleNumberChangeModal = () => updateShowNumberChangeModal(state => !state)

    return (
        <FormContext {...methods}>
            <GoPaper elevation={3}>
                <Grid container item spacing={1}>
                    <Grid item xs={12}>
                        <Typography gutterBottom variant="h4">{I18n.t("view.validationForm.title")}</Typography>
                        <Typography  gutterBottom display="block" variant="subtitle2">{I18n.t("view.validationForm.clarifyUser1")} ({userData && userData.GSM}) </Typography>
                        <Typography  gutterBottom display="block" variant="subtitle2">{I18n.t("view.validationForm.clarifyUser2")}</Typography>
                    </Grid>
                    {
                    showError && error ?
                        <Grid item xs={12}>
                            <GoError>
                                {error}
                            </GoError>
                        </Grid>
                        : null
                    }
                    <Grid item xs={12}>
                        <TextField
                            fullWidth={true}
                            inputProps={{
                                className: classes.GoTextFieldInputHuge,
                                style: {textAlign: 'center'}
                            }}
                            onChange={event => setValidationCode(event.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <GoPrimaryButton fullWidth={true} onClick={handleSubmitCode}>
                            {I18n.t("common.button.activate")}
                        </GoPrimaryButton>
                    </Grid>

                    <Grid container xs={12} justify="center">
                            <GoSecondaryButton  className={[classes.NButton, classes.Spacing]} type="button" size="small" onClick={handleGetNewCode} >{I18n.t("common.button.resend")}</GoSecondaryButton>
                            <GoSecondaryButton  className={[classes.NButton, classes.Spacing]} type="button" size="small" onClick={toggleNumberChangeModal}>{I18n.t("common.button.changeNumber")}</GoSecondaryButton>
                            <GoSecondaryButton  className={[classes.NButton, classes.Spacing]} type="button" size="small" onClick={handleLogout}>{I18n.t("common.button.logout")}</GoSecondaryButton>
                    </Grid>
                </Grid>
            </GoPaper>
            

            <GoModal showModal={showNumberChangeModal} updateModalState={toggleNumberChangeModal}>
                <FormContext {...methods}>
                    <GoPaper>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} >
                                    <VPhoneNumber
                                        label={I18n.t("common.fields.mobile")}
                                        fieldName="GSM"
                                        validation={{
                                            required: true,
                                            validate: (value) => {return PhoneNumberValidation(value)}
                                        }}
                                        helperText={`${I18n.t("common.helperText.mobileInvalid")}`}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Box className={classes.RightEndAlign} >
                                        <GoPrimaryButton type="submit">
                                            {I18n.t("common.button.updateResendCode")}
                                        </GoPrimaryButton>
                                    </Box>
                                </Grid>

                            </Grid>
                        </form>
                    </GoPaper>
                </FormContext>
            </GoModal>

        </FormContext>
    )
}

export default ValidationForm;