import React from "react";
import { Grid, Typography, Paper, Modal, Dialog, Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { makeStylesGlobal } from "../../themes/GlobalTheme"
import gonestor_motto_blue_logo from "../../assets/images/gonestor-banner-blue-logo.svg"
import gonestor_simple_blue_logo from "../../assets/images/gonestor-banner-blue-simple.svg"
import gonestor_white_whistle_logo from "../../assets/images/gonestor-white-whistle-logo.png"

import { CloseIconButton } from "./CustomInputs";


const localStyle = makeStylesGlobal(theme => ({

    GoPaper : {
        padding : theme.spacing(2),
        display: "block",
        overflowY: "auto",
        maxHeight: "90%",
    },
    CropMargin : {
        margin: -theme.spacing(2)
    },

    GoAdminToolbar : {
        padding: "16px"
    }

}))



// custom layouts, containers, grids, dialog/modals and boxes

export const CenteredGrid = styled(({ ...other }) => (
    <Grid container spacing={0} alignItems="center" justify="center" {...other} />
))({
    minHeight: "100vh"
});

export const CenteredModal = styled(({...other}) => (
    <Modal {...other} />
))({
    margin: "0px auto",
    maxWidth: "70%",
});

export const SmallCenteredModal = styled(({...other}) => (
    <Dialog {...other} />
))({
    margin: "64px auto",
    maxWidth: "35%",
    minHeight: "70vh"
});

export const GoModal = ({children, showModal, updateModalState}) => {
    // give the two following const to the parent component ; with a slightly different name if there're several modals in the same and single component (to avoid confusion) , cf. AvatarPicker.js : 
    //(1) const [showModal, updateShowModal] = useState(false)
    //(2) const toggleModal = () => updateShowModal(state => !state)
    return (
        <>
            <CenteredModal open={showModal} onClose={updateModalState} aria-labelledby="form-dialog-title">
                <>
                    <CloseIconButton onClick={updateModalState}/>
                    {children}
                </>
            </CenteredModal>
        </>
    )
}

// custom paper, bars, card, accordions

export const GoPaper = ({children}) => {

    const classes = localStyle();

    return (
        <Paper elevation={2} className={classes.GoPaper}>
            {children}
        </Paper>
    )
}

export const GoAdminToolbar = ({children}) => {
    const classes = localStyle();

    return (
        <Box className={classes.GoAdminToolbar}>
            {children}
        </Box>
    )
}


// custom styled messages, hints, blocks, logos


export const GoDataLoading = (props) => {
    const classes = localStyle();
    return (
        <div>
            Data is loading, please wait ...
        </div>
    )
}


export const GoError = styled(Typography)({
    color: "rgba(244, 67, 54, 1)",
    fontWeight: "normal",
    border: "3px double",
    borderColor: "#E57373",
    backgroundColor: "rgba(247, 225, 235, 0.5)",
    padding: "3px",
    textAlign: "center"
});

export const GoInfo = styled(Typography)({
    color: "black",
    fontWeight: "normal",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    padding: "16px",
    textAlign: "center"
});

export const GoNestorLogoMotto = ({height, width}) => {
    return (
        <img 
            src={gonestor_motto_blue_logo} //
            alt="go nestor logo and motto"
            style={{height: height, width: width}}
        />
    )
}

export const GoNestorLogoSimple = ({height, width}) => {
    return (
        <img 
            src={gonestor_simple_blue_logo} //
            alt="go nestor logo"
            style={{height: height, width: width}}
        />
    )
}

export const GoNestorWhistleLogo = ({height, width, withBackground}) => {
    const classes = localStyle();
    
    return (
        <img
            src={gonestor_white_whistle_logo}
            alt="go nestor whistle logo"
            style={{height: height, width: width, backgroundColor: withBackground ? "rgba(62, 108, 158, 1)" : null }}
            className={classes.CropMargin}
        />
    )
}
