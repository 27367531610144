import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { Grid, Box, InputAdornment, Divider, Button, colors, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { GoPaper, GoDataLoading } from "../Widgets/CustomLayoutAndSurfaces"
import { useForm, FormContext } from 'react-hook-form';
import { VSelectInput, VTextField, GoButtonGroup, VDatePicker, VDateTimePicker, VSwitcher, GoPrimaryButton } from "../Widgets/CustomInputs"
import { makeStylesGlobal } from "../../themes/GlobalTheme"
import * as cst from "../../js/constants/miscellaneous_cst"
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { authenticated } from "../../js/actions/auth";
import { computedCountriesArray } from "../../js/utils/computed"
import AvatarPicker from "../Widgets/AvatarPicker"
import { GoSecondaryButton } from '../Widgets/CustomInputs';
import RefreshIcon from '@material-ui/icons/Refresh';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { computedClubsArray } from "../../js/utils/computed";


const localStyle = makeStylesGlobal(theme => ({
    CenterAccordionSyummary: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "flex-end",
    },

    AccordionDetailsGrid: {
        display: "flex",
        alignItems: "flex-end"
    },

    AccordionSwitches: {
        display: "flex",
        justifyContent: "flex-end",
    },

    AccordionMargin: {
        margin: "8px"
    }
}));



const ProfileForm = ({
    history, rowUserID,
    isPopUp, onClose
}) => {

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    })

    const dispatch = useDispatch();

    const [countriesList, setCountriesList] = useState([])

    useEffect(() => {
        setCountriesList(computedCountriesArray())
    }, []);



    const { reset, setValue, watch, handleSubmit } = methods;
    const token = useSelector((state) => state.auth.token);
    const classes = localStyle();

    const [isFetching, setIsFetching] = useState(true);
    const [submitError, setSubmitError] = useState(false);
    const [originalData, setOriginalData] = useState({});
    const [formHasChanged, setFormHasChanged] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const [resetbyMail, setresetbyMail] = useState(true);


    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    function resetPassword(sms) {
        setresetbyMail(sms);
        setOpenDialog(true);

    }

    function refreshSportLink() {
        

        let url = cst.userRefreshSportLinkPath + rowUserID;


        var config = {
            headers: { Authorization: "Bearer " + token }
        };

        Axios.get(url, config)
            .then((response) => {

                setValue('IsIndoorReferee', response.data.IsIndoorReferee);
                setValue('IsClubOfficial', response.data.IsClubOfficial);
                setValue('IsOutdoorReferee', response.data.IsOutdoorReferee);
                setValue("LastRefreshSportLink", response.data.LastRefreshSportLink);
            })


    }



    useEffect(() => {
        const fetchData = () => {
            let url = cst.userProfilePath + rowUserID;
            var config = {
                headers: { Authorization: "Bearer " + token }
            };

            Axios.get(url, config)
                .then(function (response) {
                    setOriginalData(response.data);

                    reset(response.data)
                    setIsFetching(false)
                })

        }
        fetchData();
    }, [rowUserID, token, reset])

    const [clubList, setClubList] = useState([])
    useEffect(() => {
        async function fetchClubs() {
            let url = "/club/allfields"
            var config = {
                headers: { Authorization: "Bearer " + token }
            };
            let fetchedClubs = await Axios.get(url, config)
            setClubList(computedClubsArray(fetchedClubs.data))
        }
        fetchClubs()
    }, [])

    const submitUpdate = data => {
        setFormHasChanged(false);
        const updatedProfile = { ...originalData, ...data };

        let url = cst.userProfilePath;
        let config = {
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            }
        };
        Axios.put(url, updatedProfile, config)
            .then(response => {
                if (response.data.IsLogged === true) {
                    dispatch(authenticated(
                        response.data.IsValidated,
                        response.data.Token,
                        response.data.Role
                    ));
                }
                if (isPopUp) { return onClose() }
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.message) {
                    setSubmitError(error.response.data.message);
                } else {
                    setSubmitError("Impossible to update your profile account, please try again later.")
                }
            })

    }

    if (isFetching) {
        return <GoDataLoading />
    }


    const handleOpen = () => {
      setOpenDialog(true);
    };
  
    const handleClose = () => {
      setOpenDialog(false);
    };
  
    const handleConfirm = () => {
      // Handle your confirmation logic here
      // For example, you can submit the form or perform some action
      // Then, close the modal
        let url = cst.adminresetPasswordPath;

        

        const data = {
            email: originalData.EMail,
            gsm: originalData.GSM,
            sms : resetbyMail
          };

          
          let config = {
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            }
        };

        Axios.post(url, data, config)
            .then(response => {
               // history.goBack
            })
            .catch(error => {
              //  history.goBack
            })
      handleClose();
      reset();
      onClose();

    };

    return (
        <FormContext {...methods}>
            <GoPaper >
                <Dialog open={openDialog} onClose={handleClose}>
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you really want to reset the password of this user? This cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            No
                        </Button>
                        <Button onClick={handleConfirm} color="primary">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <form onSubmit={handleSubmit(submitUpdate)} onChange={s => { setFormHasChanged(true) }}>

                    <Grid container spacing={2}>
                        <Grid item md={2} sm={3} xs={12}>
                            <VSelectInput
                                label="Gender"
                                fullWidth={true}
                                fieldName="Gender"
                                validation={{ required: true }}
                                allValues={cst.genderListEN}
                                onClose={() => setFormHasChanged(true)}
                            />
                        </Grid>
                        <Grid item md={5} sm={4} xs={12}>
                            <VTextField
                                label="First Name"
                                fieldName="FirstName"
                                validation={{ required: true }}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item md={5} sm={5} xs={12}>
                            <VTextField
                                label="Last Name"
                                fieldName="LastName"
                                validation={{ required: true }}
                                fullWidth={true}
                            />
                        </Grid>

                        <Grid container item sm={7} xs={12}>
                            <Grid item xs={12}>
                                <VTextField
                                    label="E-mail"
                                    fieldName="EMail"
                                    validation={{ required: true }}
                                    fullWidth={true}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Box my={2}>
                                    <VTextField
                                        label="Mobile Number"
                                        fieldName="GSM"
                                        validation={{ required: true }}
                                        fullWidth={true}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <VDatePicker
                                    label="Birth Date"
                                    fieldName="BirthDate"
                                    validation={{ required: false }}
                                    fullWidth={true}
                                    openTo="year"
                                    onClose={() => setFormHasChanged(true)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item sm={5} xs={12} spacing={-1}>
                            <AvatarPicker
                                fieldName="Avatar"
                                label="Avatar"
                                validation={{ required: false }}
                                fileType="img"
                                onChange={() => setFormHasChanged(true)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Box >
                                <VTextField
                                    label="Address"
                                    fieldName="Address"
                                    validation={{ required: false }}
                                    fullWidth={true}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Box >
                                <VTextField
                                    label="Range"
                                    fieldName="Range"
                                    validation={{ required: false }}
                                    fullWidth={true}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: <InputAdornment position="start">km</InputAdornment>
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <VSelectInput
                                label="Club"
                                fieldName="ClubName"
                                validation={{ required: true }}
                                allValues={clubList}
                                onClose={() => setFormHasChanged(true)}
                            />
                        </Grid>

                        <Grid item xs={12} className={classes.AccordionMargin}>
                            <Accordion square expanded={watch('IsReferee')}>
                                <AccordionSummary className={classes.CenterAccordionSyummary}>
                                    <Grid item sm={12} xs={12}
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        direction="row"
                                        justify="center"
                                    >
                                        <Grid item sm={12} xs={12}>
                                            <VSwitcher
                                                fieldName="IsReferee"
                                                label="Umpire"

                                            />
                                        </Grid>

                                    </Grid>
                                </AccordionSummary>
                                <Divider />
                                <AccordionDetails >
                                    <Grid container spacing={1} className={classes.AccordionDetailsGrid}>
                                        <Grid item container sm={4} xs={12}>
                                            <VTextField
                                                label="Umpire #"
                                                fieldName="RefereeNumber"
                                                validation={{ required: false, maxLength: 7 }}
                                                fullWidth={true}
                                                InputProps={{
                                                }}
                                            />
                                        </Grid>
                                        <Grid item container spacing={1} sm={8} className={classes.AccordionSwitches}>
                                            <Grid item sm={3} xs={12} >
                                                <VSwitcher
                                                    label="Club Official"
                                                    fieldName="IsClubOfficial"
                                                    size="small"
                                                />
                                            </Grid>    
                                            <Grid item sm={3} xs={12}>
                                                <VSwitcher
                                                    label="Indoor"
                                                    fieldName="IsIndoorReferee"
                                                    size="small"
                                                />
                                            </Grid>
                                            <Grid item sm={3} xs={12}>
                                                <VSwitcher
                                                    label="Outdoor"
                                                    fieldName="IsOutdoorReferee"
                                                    size="small"
                                                />
                                            </Grid>
                                            <Grid item sm={3} xs={12}>
                                                <VSwitcher
                                                    label="National"
                                                    fieldName="IsNationalReferee"
                                                    size="small"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item container spacing={1} >
                                            <Grid item container sm={4} xs={12}>
                                                <VDateTimePicker
                                                    label="Last Refresh"
                                                    fieldName="LastRefreshSportLink"
                                                    validation={{ required: false }}
                                                    fullWidth={true}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item container sm={3} xs={12} className={classes.ButtonsContainer}>
                                                <GoSecondaryButton type="button" onClick={() => refreshSportLink()} size="small" startIcon={<RefreshIcon />}>
                                                    Refresh SportLink
                                                </GoSecondaryButton>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <GoPrimaryButton disabled={formHasChanged} onClick={() => resetPassword(false)}>e-mail Reset Password</GoPrimaryButton>
                            &nbsp;
                            <GoPrimaryButton  disabled={formHasChanged} onClick={() => resetPassword(true)}>SMS Reset Password</GoPrimaryButton>
                        </Grid>
                        <Grid item xs={12}>
                            {formHasChanged ?
                                <Box className={classes.RightEndAlign}>
                                    <GoButtonGroup
                                        secondaryLabel="Cancel"
                                        secondaryType="button"
                                        secondaryOnClick={isPopUp ? onClose : history.goBack}
                                        primaryLabel="Update"
                                        primaryType="submit"
                                    />
                                </Box>
                                :
                                <></>
                            }
                        </Grid>
                    </Grid>
                </form>
            </GoPaper>
        </FormContext>
    );
}


export default ProfileForm;