import React, { useRef, useState } from "react";
import { Box, Grid, TablePagination, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import MaterialTable, {
    MTableBodyRow,
    MTableEditRow,
    MTableToolbar,
} from "material-table";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { DEMANDURL } from "../../js/constants/model_cst";
import { fetchData, getDownload, excelSpreadsheetMIME, buildUrl } from "../../js/utils/backend";
import { GoPrimaryButton } from '../Widgets/CustomInputs';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
    DateTimeHourRenderer,
    AvatarThumbnail,
    StatusRenderer,
} from "../Widgets/TableEditors";
import * as cst from "../../js/constants/miscellaneous_cst";
import { useReduxPrefsState } from "../../js/actions/prefs";
import { RatingStars } from "../Widgets/CustomInputs";
import { GoAdminToolbar } from "../Widgets/CustomLayoutAndSurfaces";

const localStyle = makeStylesGlobal(theme => ({
    EvaluationCell: { display: "flex", flexDirection: "column" },
    EvaluationStars: { display: "flex" },
}));

const Demands = props => {
    const [isTableEditing, setIsTableEditing] = useState(false);
    const [exporting, setExporting] = useState(false);

    const token = useSelector(state => state.auth.token);
    const classes = localStyle();
    const tableRef = useRef();

    const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);
    const [searchQuery, setSearchQuery] = useState(null);

    const handleDownloadClick = async () => {
        setExporting(true); // Disable the button before starting the download

        try {
            let url = buildUrl(cst.demandsExportToExcel, searchQuery);
            await getDownload(
                url,
                token,
                excelSpreadsheetMIME,
               `Demands`
            );

            // After download is complete, enable the button again
            setExporting(false);
        } catch (error) {
            // Handle download errors here
            console.error('Download failed:', error);
            setExporting(false);
        }
    };

    function refreshTable() {
        if (tableRef && tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }
    const debounceSearch = debounce((query) => {
        setSearchQuery(query);
      }, 1000); // Adjust the debounce delay as needed (e.g., 500 milliseconds)

    function debounce(func, delay) {
        let timer;
        return function (...args) {
          clearTimeout(timer);
          timer = setTimeout(() => {
            func(...args);
          }, delay);
        };
      }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <MaterialTable
                        tableRef={tableRef}
                        title={"Demands"}
                        columns={[
                            {
                                title: "Avatar",
                                field: "Avatar",
                                width: "1%",
                                cellStyle: { padding: "4px", width: "80px" },
                                sorting: false,
                                render: rowData =>
                                    AvatarThumbnail(
                                        60,
                                        rowData.OriginalOwner.Avatar
                                    ),
                            },
                            {
                                title: "First Name",
                                field: "FirstName",
                                sorting: false,
                                render: rowData =>
                                    rowData.OriginalOwner.FirstName,
                            },
                            {
                                title: "Last Name",
                                field: "LastName",
                                sorting: false,
                                render: rowData =>
                                    rowData.OriginalOwner.LastName,
                            },
                            {
                                title: "Date",
                                field: "GameTime",
                                type: "datetime",
                                defaultSort: "desc",
                                render: rowData =>
                                    DateTimeHourRenderer(150, rowData.GameTime),
                            },
                            {
                                title: "Club",
                                field: "ClubName",
                            },
                            {
                                title: "Type",
                                field: "TypeName",
                            },
                            {
                                title: "Category",
                                field: "CategoryName",
                            },
                            {
                                title: "Evaluations",
                                field: "",
                                sorting: false,
                                render: rowData => {
                                    const umpireRating =
                                        rowData?.OriginalProviders &&
                                        rowData.OriginalProviders[0]
                                            ?.Evaluation;
                                    const homeRating =
                                        rowData?.OriginalProviders &&
                                        rowData.OriginalProviders[0]
                                            ?.HomeTeamEvaluation;
                                    const awayRating =
                                        rowData?.OriginalProviders &&
                                        rowData.OriginalProviders[0]
                                            ?.AwayTeamEvaluation;
                                    return (
                                        <Box className={classes.EvaluationCell}>
                                            {umpireRating >= 1 ? (
                                                <Box
                                                    className={
                                                        classes.EvaluationStars
                                                    }
                                                >
                                                    U :
                                                    <RatingStars
                                                        value={umpireRating}
                                                        readOnly={true}
                                                    />
                                                </Box>
                                            ) : null}
                                            {homeRating >= 1 ? (
                                                <Box
                                                    className={
                                                        classes.EvaluationStars
                                                    }
                                                >
                                                    H :
                                                    <RatingStars
                                                        value={homeRating}
                                                        readOnly={true}
                                                    />
                                                </Box>
                                            ) : null}
                                            {awayRating >= 1 ? (
                                                <Box
                                                    className={
                                                        classes.EvaluationStars
                                                    }
                                                >
                                                    A :
                                                    <RatingStars
                                                        value={awayRating}
                                                        readOnly={true}
                                                    />
                                                </Box>
                                            ) : null}
                                        </Box>
                                    );
                                },
                            },
                            {
                                title: "Status",
                                field: "HasDesignedProvider",
                                sorting: false,
                                headerStyle: { textAlign: "center" },
                                cellStyle: { textAlign: "center" },
                                render: rowData =>
                                    StatusRenderer(
                                        rowData.HasDesignedProvider,
                                        cst.designedProviderStatus
                                    ),
                            },
                        ]}
                        data={query => 
                            {   
                              return fetchData(token, query, DEMANDURL).finally
                              ( response =>
                                {
                                    
                                    debounceSearch(query);
                                }
                                
                              )
                            }}
    
                        options={{
                            pageSize: rows,
                            pageSizeOptions: cst.itemsPerPage,
                            actionsColumnIndex: 50,
                            emptyRowsWhenPaging: false,
                            sorting: true,
                            search: true,
                        }}
                        onChangeRowsPerPage={nbRows => setRows(nbRows)}
                        components={{
                            EditRow: props => {
                                setIsTableEditing(true);
                                return <MTableEditRow {...props} />;
                            },
                            Row: props => {
                                setIsTableEditing(true);
                                return <MTableBodyRow {...props} />;
                            },
                            Pagination: props => (
                                <TablePagination
                                    {...props}
                                    className={
                                        isTableEditing ? classes.disabled : null
                                    }
                                />
                            ),
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                    <GoAdminToolbar>

                                    <GoPrimaryButton type="button" startIcon={<GetAppIcon />}
                                        disabled={exporting}
                                        onClick={handleDownloadClick}>Export to Excel

                                    </GoPrimaryButton>
                                    &nbsp;
                                    {exporting ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    </GoAdminToolbar>

                                </div>
                            )
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Demands;