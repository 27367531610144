import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";


import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Box, Popper, Fade, IconButton, MenuList, MenuItem } from "@material-ui/core";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ChangePasswordIcon from '@material-ui/icons/Lock';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

var I18n = require('react-redux-i18n').I18n;



const useStyles = makeStyles(theme => ({
    AccountMenu : {
        display: "flex",
        padding: theme.spacing(1),
    },
    MenuIcon : {
        marginRight: theme.spacing(2)
    },
    UserDisplay : {
        padding: theme.spacing(1)
    },
    PopperOver : {
        zIndex: 1210 // (MUI Drawer component has a zIndex of 1200)
    },
    choiceIcons: {
        marginRight: "20px",
    },
    IconButton: {
        color: "white"
    }

}));

const AccountMenu = ({clubName, layoutType}) => {

    const classes = useStyles();
    const history = useHistory();

    const userName = useSelector((state) => state.auth.displayName);
    const isClubManager = useSelector((state) => state.auth.isClubManager)
    const role = useSelector((state) => state.auth.role);

    const isAdmin = role === "Admin"

    const isAdminLayout = layoutType === "/admin";
    const isClubLayout = layoutType === "/team";
    
    const [open, setOpen] = useState(false);

    const anchorRef = useRef(null);

    const handleMenuToggle = (event) => {
        setOpen((prevOpen) => !prevOpen);
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleClickAway = (event) => {
        setOpen(false)
    }



    return (
        <ClickAwayListener onClickAway={handleClickAway}>

        <div className={classes.AccountMenu}>
            <Box onClick={handleMenuToggle} ref={anchorRef}>   
                <IconButton className={classes.IconButton}>
                    <PersonIcon/>
                </IconButton>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    className={classes.PopperOver}
                    placement="bottom-end"
                    transition
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper  className={classes.UserDisplay}>
                                <Typography align="center">{`${userName}`}</Typography>
                                {isClubManager && isClubLayout && <Typography align="center">{`${clubName}`}</Typography>}
                                <MenuList>
                                    <MenuItem onClick={() => history.push("/change-password")}>
                                        <ChangePasswordIcon className={classes.MenuIcon}/>
                                        <Typography>{I18n.t("view.accountMenu.changePassword")}</Typography>
                                    </MenuItem>

                                    {isAdmin && isClubLayout && 
                                        (<MenuItem onClick={() => history.push("/admin")}>
                                                <SupervisorAccountIcon className={classes.MenuIcon}/>
                                                {I18n.t("view.accountMenu.administration")}
                                        </MenuItem>)
                                    }

                                    {isAdmin && isAdminLayout  && isClubManager &&
                                        (<MenuItem onClick={() => history.push("/team")}>
                                                <SupervisorAccountIcon className={classes.MenuIcon}/>
                                                {I18n.t("view.accountMenu.clubManager")}
                                        </MenuItem>)
                                    }

                                    

                                    <MenuItem onClick={() => history.push("/logout")}>
                                        <ExitToAppIcon className={classes.MenuIcon}/>
                                        <Typography>{I18n.t("common.button.logout")}</Typography>
                                    </MenuItem>
                                </MenuList>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </Box>
        </div>
        </ClickAwayListener>

    )
}

export default AccountMenu