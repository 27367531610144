import React from 'react'
import { CenteredGrid, GoPaper } from "../Widgets/CustomLayoutAndSurfaces"
import { Box, Grid, Typography } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { makeStylesGlobal } from "../../themes/GlobalTheme";
import { GoPrimaryButton, GoSecondaryButton } from "../Widgets/CustomInputs"

const useStyles = makeStylesGlobal((theme) => ({

}));

const UserView = () => {

    const classes = useStyles();
    const displayName = useSelector(state => state.auth.displayName);

    const history = useHistory();


    return (
        <div className={classes.GoBackgroundColor}>
            <CenteredGrid>
                <Box width={400}>
                    <GoPaper elevation={3}>
                        <Grid container item spacing={1}>
                            <Grid item xs={12}>
                                <Typography align="center" gutterBottom variant="h6">Welcome {displayName}, you are logged as user.</Typography>
                            </Grid>
                            <Grid item container>
                                <Grid item xs={6}>
                                    <GoSecondaryButton  onClick={() => history.push("/change-password")}>Change Password</GoSecondaryButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <GoPrimaryButton fullWidth={true}  onClick={() => history.push("/logout")}>Logout</GoPrimaryButton>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                    </GoPaper>
                </Box>
            </CenteredGrid>
        </div>
    )
}

export default UserView;


