import React, {useState, useEffect} from "react";
import { TextField, Typography, Grid, Box, Link, Button} from "@material-ui/core";
import { useDispatch } from 'react-redux';
import { authorize, reseterror } from "../../js/actions/auth"
import { GoPrimaryButton, PasswordTextfield, LanguageSelector} from "../Widgets/CustomInputs"
import { GoPaper, GoError, GoNestorLogoMotto } from "../Widgets/CustomLayoutAndSurfaces"
import { makeStylesGlobal } from "../../themes/GlobalTheme"
import RouterLink from "../../routes/RouterLink"
import { useSelector } from 'react-redux';
import * as cst from "../../js/constants/miscellaneous_cst.js"
import {setLocale} from "react-redux-i18n";
var I18n = require('react-redux-i18n').I18n;

const useStyles = makeStylesGlobal((theme) => ({

    

}));

const LoginForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const lastError = useSelector(state => state.auth.errorMessage);

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");

    function handleSubmit (data)  {
        dispatch(authorize(email, password))
    };

    useEffect(() => {
        dispatch(reseterror());
    }, [])

    return (
        <>
            <Box align="center" mb={4}>
                <GoNestorLogoMotto/>
            </Box>
            <GoPaper elevation={3}>
                <Grid container spacing={3}>

                    <Grid item xs={4} >
                        <LanguageSelector/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center" variant="h4" className={classes.FormTitle}>
                            {I18n.t("view.loginForm.title")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth={true}
                            variant="outlined"
                            label={I18n.t("common.fields.email")}
                            onChange={event => setEmail(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PasswordTextfield
                            label={I18n.t("common.fields.password")}
                            onKeyPress={ (e) => {
                                if (e.key === 'Enter') {
                                    handleSubmit();
                                }
                            }}
                            onChange={event => setPassword(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} align="left">
                        <Typography variant="caption">
                            <Link component={RouterLink} to="/reset-password">
                                {I18n.t("common.links.forgotPassword")}
                            </Link>
                        </Typography>
                    </Grid>

                    {lastError ? 
                        <Grid item xs={12}>
                                <GoError>{lastError}</GoError>
                        </Grid> : null
                    }

                    <Grid item xs={12}>
                        <GoPrimaryButton type="submit" onClick={handleSubmit} fullWidth={true}>
                            {I18n.t("common.button.signIn")}
                        </GoPrimaryButton>
                        
                    </Grid>
                    <Grid item xs={12} align="left">
                        <Box my={1}  >
                            <Typography variant="h6">
                                {I18n.t("view.loginForm.subtitle")}
                            </Typography>
                            <Typography variant="caption" disabled>
                                <Link component={RouterLink} to="/register">
                                    {I18n.t("common.links.signUp")}
                                </Link>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </GoPaper>
        </>
    )
}

export default LoginForm