import React, { useRef, useState } from "react";
import { Grid, TablePagination, Box, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";

import MaterialTable, { MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";
import { makeStylesGlobal } from "../../themes/GlobalTheme"

import { MATCHESURL } from "../../js/constants/model_cst"
import { fetchData, getDownload, excelSpreadsheetMIME, buildUrl } from "../../js/utils/backend"

import { DateTimeHourRenderer, AvatarThumbnail } from "../Widgets/TableEditors"
import { GoPrimaryButton } from "../Widgets/CustomInputs"
import * as cst from "../../js/constants/miscellaneous_cst"; 
import { useReduxPrefsState } from "../../js/actions/prefs"
import GetAppIcon from '@material-ui/icons/GetApp';
import { GoAdminToolbar } from "../Widgets/CustomLayoutAndSurfaces";


var I18n = require('react-redux-i18n').I18n;


const localStyle = makeStylesGlobal(theme => ({

}));


const Matches = (props) => {
    const token = useSelector(state => state.auth.token);
    const [exporting, setExporting] = useState(false);
    const [searchQuery, setSearchQuery] = useState(null);
    const classes = localStyle();
    const tableRef = useRef();

    const handleDownloadClick = async () => {
        setExporting(true); // Disable the button before starting the download

        try {
            let url = buildUrl(cst.matchesExportToExcel,searchQuery);
            await getDownload(
                url,
                token,
                excelSpreadsheetMIME,
               `Matches`
            );

            // After download is complete, enable the button again
            setExporting(false);
        } catch (error) {
            // Handle download errors here
            console.error('Download failed:', error);
            setExporting(false);
        }
    };

    const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);

    function refreshTable() { 
        if (tableRef && tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }

    const debounceSearch = debounce((query) => {
        setSearchQuery(query);
      }, 1000); // Adjust the debounce delay as needed (e.g., 500 milliseconds)

    function debounce(func, delay) {
        let timer;
        return function (...args) {
          clearTimeout(timer);
          timer = setTimeout(() => {
            func(...args);
          }, delay);
        };
      }

    return (
        <>
        <Grid container>
            <Grid item xs={12}>
                <MaterialTable
                    tableRef={tableRef}
                    title={"Matches"}
                        columns={[
                            {
                                title: "#", 
                                field: "MatchRef", 
                                width: "10%"
                            },
                        {
                            title: "When", 
                            field: "When", 
                            defaultSort: 'desc',
                            type: "datetime",
                            width: "10%",
                            render: rowData => DateTimeHourRenderer(150, rowData.When)
                        },
                        {
                            title: "Home", 
                            field: "Logo",
                            sorting: false,
                            editable: "never",
                            width: "1%",
                            cellStyle: { padding: "4px", width:"80px"},
                            render: rowData => AvatarThumbnail(60, rowData.HomeTeamImage)
                        },
                        {
                            title: "", 
                            field: "HomeTeamName", 
                        },
                        {
                            title: "Away", 
                            field: "Logo",
                            sorting: false,
                            editable: "never",
                            width: "1%",
                            cellStyle: { padding: "4px", width:"80px"},
                            render: rowData => AvatarThumbnail(60, rowData.AwayTeamImage)
                        },
                        {
                            title: "", 
                            field: "AwayTeamName", 
                        },
                        {
                            title: "Category", 
                            sorting: false,
                            field: "CategoryNameEN"
                        },
                        {
                            title: "Field", 
                            sorting: false,
                            field: "FieldName"
                        }
                    ]}
                    data={query => 
                        {   
                          return fetchData(token, query, MATCHESURL).finally
                          ( response =>
                            {
                                
                                debounceSearch(query);
                            }
                            
                          )
                        }}

                    options={{
                        pageSize: rows,
                        actionsColumnIndex: 50,
                        pageSizeOptions: cst.itemsPerPage,
                        emptyRowsWhenPaging:false,
                        sorting: true,
                        search: true,
                    }}
                    onChangeRowsPerPage={(nbRows)=>setRows(nbRows)}
                    components={{
                        Row: props => {  return (<MTableBodyRow {...props} />)},
                        Pagination: props=>(<TablePagination {...props} />) ,
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} />
                                <GoAdminToolbar>
                                <GoPrimaryButton type="button"  startIcon={<GetAppIcon />}
                                    disabled={exporting}
                                    onClick={handleDownloadClick}>Export to Excel

                                </GoPrimaryButton>
                                &nbsp;
                                {exporting ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                </GoAdminToolbar>

                            </div>
                        )
                    }}
                />
            </Grid>
        </Grid>
        </>
    )
}

export default Matches;