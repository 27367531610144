import React, { useRef, useState } from "react";
import { Grid, TablePagination } from "@material-ui/core";
import { useSelector } from "react-redux";
import MaterialTable, { MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";
import { makeStylesGlobal } from "../../themes/GlobalTheme"
import { fetchData } from "../../js/utils/backend"
import * as cst from "../../js/constants/miscellaneous_cst"
import { GoAdminToolbar } from "../Widgets/CustomLayoutAndSurfaces"
import { useReduxPrefsState } from "../../js/actions/prefs"
import { AvatarThumbnail, boolRender } from "../Widgets/TableEditors"

var I18n = require('react-redux-i18n').I18n;


const localStyle = makeStylesGlobal(theme => ({

}));

const Members = (props) => {

    const [isTableEditing, setIsTableEditing] = useState(false);

    const token = useSelector(state => state.auth.token);
    const classes = localStyle();
    const tableRef = useRef();

    const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);
    const [operation, setOperation] = useState("none")

    const operationChange = newOperation => {
        switch(newOperation) {
            case "none":
                break;
            default: 
                return null;
        }
        setOperation(newOperation);
    }

    function refreshTable() { 
        if (tableRef && tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }

    return (
        <>
        <Grid container>
            <Grid item xs={12}>
                <MaterialTable
                    tableRef={tableRef}
                    title={I18n.t("view.teamMenu.members")}
                    localization={I18n.t("materialTable")}
                    columns={[
                        {
                            title: "Avatar", 
                            field: "Avatar",
                            sorting: false,
                            editable: "never",
                            cellStyle: { padding: "4px 0px 0px 10px"},
                            render: rowData => AvatarThumbnail(60, rowData.Avatar)
                        },
                        {
                            title: `${I18n.t("materialTable.header.firstName")}`,
                            field: "FirstName",
                            defaultSort: "asc",
                        },
                        {
                            title: `${I18n.t("materialTable.header.lastName")}`, 
                            field: "LastName", 
                            editable: "never", 
                            defaultSort: 'asc'
                        },
                        {
                            title: `${I18n.t("materialTable.header.indoorReferee")}`,
                            field: "IsIndoorReferee",
                            type: "boolean",
                            headerStyle: { textAlign: "center"},
                            cellStyle: { textAlign: "center"},
                            render: rowData => boolRender(rowData.IsClubManager)
                        },
                        {
                            title: `${I18n.t("materialTable.header.outdoorReferee")}`,
                            field: "IsOutdoorReferee",
                            type: "boolean",
                            headerStyle: { textAlign: "center"},
                            cellStyle: { textAlign: "center"},
                            render: rowData => boolRender(rowData.IsClubManager)
                        },
                    ]}
                    data={ query => fetchData(token, query, cst.clubMembersPath) }
                    options={{
                        pageSize: rows,
                        pageSizeOptions: cst.itemsPerPage,
                        actionsColumnIndex: 50,
                        draggable: false,
                        emptyRowsWhenPaging:false,
                        detailPanelType: 'single',
                        sorting: true,
                        search: true,
                    }}
                    onChangeRowsPerPage={(nbRows)=>setRows(nbRows)}
                    components={{
                        EditRow: props => { setIsTableEditing(true); return (<MTableEditRow {...props} />)},
                        Row: props => { setIsTableEditing(true); return (<MTableBodyRow {...props} />)},
                        Pagination: props=>(<TablePagination {...props} className={isTableEditing ?classes.disabled:null} />) ,
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} /> 
                                <GoAdminToolbar>
                                </GoAdminToolbar>
                            </div>
                        )
                    }}
                />
            </Grid>  
        </Grid>
        
        </>
    )
}

export default Members;