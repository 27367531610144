import Axios from "axios";
import {useRef, useEffect, useState} from "react";


export const excelSpreadsheetMIME = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"


export function editModel(newObject, id, token, url) {
  return new Promise((resolve, reject) => {
    var config = {
      headers: { Authorization: "Bearer " + token }
    };
    Axios.put(url + "/" + id, newObject, config)
      .then(function (response) {
        resolve();
      })
      .catch(function (error) {
        console.error(error);
        console.log(newObject);
        reject();
      });
  });
}

export function addNewModel(newObject, token, url) {
  return new Promise((resolve, reject) => {
    var config = {
      headers: { Authorization: "Bearer " + token }
    };
    Axios.post(url, newObject, config)
      .then(function (response) {
        resolve();
      })
      .catch(function (error) {
        console.error(error);
        reject();
      });
  });
}


export function deleteModel(rowid, token, url) {
  return new Promise((resolve, reject) => {
    var config = {
      headers: { Authorization: "Bearer " + token }
    };
    Axios.delete(url + "/" + rowid, config)
      .then(function (response) {
        resolve();
      })
      .catch(function (error) {
        console.error(error);
        reject();
      });
  });
}

export function buildUrl(baseURL, query)
{
  let url = baseURL + "?";
  url += "pageSize=" + query.pageSize;
  url += "&pageNumber=" + (query.page + 1);
  url += "&search=" + query.search;
  return url;
}   


export function fetchData(
  token,
  query,
  baseURL,
  optionalFetchOptions
) {



  return new Promise((resolve, reject) => {
    let url = baseURL + "?";
    url += "pageSize=" + query.pageSize;
    url += "&pageNumber=" + (query.page + 1);
    url += "&search=" + query.search;
    if (optionalFetchOptions) {
      url += optionalFetchOptions
    }

    if (query.orderBy) {
      url += "&orderBy=" + query.orderBy.field;
      url += "&dir=" + query.orderDirection;
    }
   
    var config = {
      headers: { Authorization: "Bearer " + token }
    };
    Axios.get(url, config)
      .then(function (response) {
        resolve({
          data: response.data.Data,
          page: response.data.PageIndex - 1,
          totalCount: response.data.TotalCount
        });
      })
      .catch(function (error) {
        resolve({ data: [], page: 0, totalCount: 0 });
      });
  });
}
export const useHistoryState = (label, initValue) =>
{
    const [state,setState] = useState(window.history.state && window.history.state[label] ? window.history.state[label] : initValue);

    const updateState = (t,silent) => {
        const existingState = window.history.state ? window.history.state : {[label]: undefined};
        if(!silent && state !== t) {
            setState(t);
        }
        const newState = {...existingState, [label]: t};
        window.history.replaceState(newState,"");
        console.log("HistoryState",window.history.state);
    };

    return [state,(t,silent) => updateState(t,silent)];
}



export const downloadFile = (
  token,
  query,
  baseURL,
  type,
  optionalFetchOptions,
  
) => {
  var config = {
    headers: { Authorization: "Bearer " + token }
  };
  let url = baseURL + "?"
  url += "&search=" + query.search;
  
  if (optionalFetchOptions) {
    url += optionalFetchOptions
  }
  if (query.orderBy) {
    url += "&orderBy=" + query.orderBy.field;
    url += "&dir=" + query.orderDirection;
  }
  Axios.get(url, config)
    .then((response) => {
      let fileName = response.headers["content-disposition"].split("filename")[1]
      fileName = fileName.slice(1, fileName.length - 2)
      var encodedUri = window.URL.createObjectURL(new Blob([response.data], { type: type }))
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", fileName);
      document.body.appendChild(link); // Required for FF
      link.click()
      document.body.removeChild(link);
    })
}

export function getDownload(
  url,
  token,
  type, 
  fileName = "Excel",
  bodyOfRequest = null
) {
  return new Promise((resolve, reject) => {

    

  const fetching = () => {
      let config = {
          headers: { Authorization: "Bearer " + token },
          responseType: "arraybuffer",
      };
      if (Boolean(bodyOfRequest)) {
          return Axios.post(url, bodyOfRequest, config);
      }
      return Axios.get(url, config);
  };

  fetching().then(response => {
      const encodedUri = window.URL.createObjectURL(
          new Blob([response.data], { type: type })
      );
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resolve();
  });
   
  }) 

  
}


