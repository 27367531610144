import React from 'react'
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import RouterLink from '../../routes/RouterLink'

import { makeStylesGlobal } from "../../themes/GlobalTheme";


const useStyles = makeStylesGlobal((theme) => ({

}));

const PathChooser = () => {

    const classes = useStyles();

    const role = useSelector(state => state.auth.role);
    const isManager = useSelector(state => state.auth.isClubManager);
    const isUser = role === "User"
    const isAdmin = role === "Admin"

    if (isManager) {
        return <Redirect component={RouterLink} to='/team' />
    } else if (isUser) {
        return <Redirect component={RouterLink} to='/user' />
    } else if (isAdmin) {
        return <Redirect component={RouterLink} to='/admin' />
    } else {
        return null
    }

    return (
        <>
            {/* chooser screen to display in a further development */}
        </>
    )
}

export default PathChooser;