import React, { useState } from 'react'
import RouterLink from '../../routes/RouterLink'
import { Redirect } from 'react-router-dom';

import { ThemeProvider } from "@material-ui/core/styles";

import { CenteredGrid, GoPaper, GoError } from "../Widgets/CustomLayoutAndSurfaces"
import { makeStylesGlobal } from "../../themes/GlobalTheme";

import { Typography, Grid, Box, Link } from "@material-ui/core";
import Axios from 'axios';
import { GoPrimaryButton, VPasswordTextfield, VTextField } from '../Widgets/CustomInputs';

import { useForm, FormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { authenticated } from "../../js/actions/auth";

import * as cst from "../../js/constants/miscellaneous_cst"


const localStyle = makeStylesGlobal(theme => ({
    FormTitle: {
        padding: theme.spacing(2),
    }
}));

const ChangePassword = () => {
    const classes = localStyle();

    const methods = useForm({
        mode: "onchange",
        reValidateMode: "onChange"
    })

    const {handleSubmit, watch} = methods;

    const dispatch = useDispatch();


    const [redirectToLogin, setRedirectToLogin] = useState(false)
    const [submitError, setSubmitError] = useState(false);

    const onSubmit = data => {
        let url = `user/password` // intentional error !!! 
        
        const dataToSubmit = {
            "Password": data.Password,
            "Username": data.Username,
            "OldPassword": data.OldPassword
        }


        Axios.post(url, dataToSubmit)
            .then(response => {
                if (response.data.IsLogged === true) {
                    dispatch(authenticated(
                        response.data.IsValidated,
                        response.data.Token,
                        response.data.Role, 
                        response.data.DisplayName,
                    ))
                }
                setRedirectToLogin(true)
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.message) {
                    setSubmitError(error.response.data.message);
                } else {
                    setSubmitError(cst.errorPasswordChange)
                }
            })
    }
    ////

    if(redirectToLogin === true) {
        return <Redirect component={RouterLink} to="/login" />
    }


    return (
        <div className={classes.GoBackgroundColor}>
            <CenteredGrid>
                <ThemeProvider>
                    <Box width={400}>
                        <GoPaper elevation={2}>
                            <FormContext {...methods}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} className={classes.FormTitle}>
                                            <Typography variant="h4">Change your password</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <VTextField
                                                fieldName="Username"
                                                label="E-mail"
                                                validation={{
                                                    required: true,
                                                    pattern: {
                                                        value: cst.emailRegex,
                                                        message: cst.msgEmailInvalid
                                                    }
                                                }}
                                                fullWidth={true}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <VPasswordTextfield
                                                fieldName="OldPassword"
                                                label="Old Password"
                                                fullWidth={true}
                                                variant="outlined"
                                                validation={{
                                                    required: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography variant="caption">
                                                <Link component={RouterLink} to="/reset-password">
                                                    Forgot Password ?
                                                </Link>
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <VPasswordTextfield
                                                label="New Password"
                                                fieldName="Password"
                                                fullWidth={true}
                                                variant="outlined"
                                                validation={{
                                                    required: true,
                                                    pattern: {
                                                        value: cst.passwordRegex,
                                                        message: cst.msgPasswordInvalid
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <VPasswordTextfield
                                                label="Confirm New Password"
                                                fieldName="ConfirmNewPassword"
                                                variant="outlined"
                                                fullWidth={true}
                                                validation={{
                                                    required: true,
                                                    validate: (value) => {return value === watch('Password') ? true : cst.msgPasswordUnmatching ;},
                                                    pattern: {
                                                        message: cst.msgPasswordUnmatching
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        {submitError ?
                                            <Grid item xs={12}>
                                                <GoError>{submitError}</GoError>
                                            </Grid> : null}

                                        <Grid item xs={12}>
                                            <GoPrimaryButton type="submit" onClick={handleSubmit} fullWidth={true}>
                                                Change my password
                                            </GoPrimaryButton>
                                        </Grid>
                                    </Grid>
                                </form>
                            </FormContext>

                        </GoPaper>
                    </Box>
                </ThemeProvider>
            </CenteredGrid>
        </div>
    )
}

export default ChangePassword