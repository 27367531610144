const countryList = require('country-list');
let namesArray = countryList.getNames();
let codesArray = countryList.getCodes();

var labelNames = namesArray.map(function(values, index){
    return values
})
var valueCodes = codesArray.map(function(values, index){
    return values
})

export const computedCountriesArray = () => {
    var labelCountry;
    var valueCountry;
    var result = [];
    for (let i = 0; i < labelNames.length; i++) {
        labelCountry = labelNames[i];
        valueCountry = valueCodes[i];
        result[i] ={label: labelCountry, value: valueCountry}
    };
    return result;
}


export const computedClubsArray = (data) => {    
    var clubId = data.map(function(values, index){
        return values.ID
    })
    
    var clubName = data.map(function(values, index){
        return values.Name
    })

    var clubCode = data.map(function(values, index){
        return values.Code
    })
    
    var clubLabel;
    var clubKeyId;
    var clubKeyCode; 
    var clubList = [];

    for (let i = 0; i < clubId.length; i++) {
        clubKeyId = clubId[i];
        clubLabel = clubName[i];
        clubKeyCode = clubCode[i];
        clubList[i] ={value: clubLabel, label: clubLabel, ID: clubKeyId, Code: clubKeyCode}
    };
    
    return clubList;

}


export const base64toBlob = (dataUri) => {
    var byteString = atob(dataUri.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
}