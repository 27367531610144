import React, { useRef, useState } from "react";
import { Grid, TablePagination, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import MaterialTable, { MTableBodyRow, MTableEditRow, MTableToolbar } from "material-table";
import { makeStylesGlobal } from "../../themes/GlobalTheme"
import { CATEGORYURL } from "../../js/constants/model_cst"
import { fetchData, deleteModel, getDownload, excelSpreadsheetMIME, buildUrl  } from "../../js/utils/backend"
import * as cst from "../../js/constants/miscellaneous_cst"
import { GoAdminToolbar, GoPaper } from "../Widgets/CustomLayoutAndSurfaces"
import { GoPrimaryButton } from "../Widgets/CustomInputs"
import { useReduxPrefsState } from "../../js/actions/prefs"
import CategoryEditPanel from "../EditPanel/CategoryEditPanel"
import { boolRender } from "../Widgets/TableEditors"
import AddIcon from "@material-ui/icons/AddCircleOutline";
import GetAppIcon from '@material-ui/icons/GetApp';


const localStyle = makeStylesGlobal(theme => ({

}));

const Categories = (props) => {

    const [isTableEditing, setIsTableEditing] = useState(false);
    const [exporting, setExporting] = useState(false);

    const token = useSelector(state => state.auth.token);
    const classes = localStyle();
    const tableRef = useRef();

    const [rows, setRows] = useReduxPrefsState("nbRows", cst.defaultPageSize);
    const [searchQuery, setSearchQuery] = useState(null);
    const [operation, setOperation] = useState("none")

    const operationChange = newOperation => {
        switch(newOperation) {
            case "none":
                break;
            case "new":
                break;
            default: 
                return null;
        }
        setOperation(newOperation);
    }

    const handleDownloadClick = async () => {
        setExporting(true); // Disable the button before starting the download

        try {
            let url = buildUrl(cst.categoryExportToExcel,searchQuery);
            await getDownload(
                url,
                
                token,
                excelSpreadsheetMIME,
                `Categories`
            );

            // After download is complete, enable the button again
            setExporting(false);
        } catch (error) {
            // Handle download errors here
            console.error('Download failed:', error);
            setExporting(false);
        }
    };

    function refreshTable() { 
        if (tableRef && tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }

    const debounceSearch = debounce((query) => {
        setSearchQuery(query);
      }, 1000); // Adjust the debounce delay as needed (e.g., 500 milliseconds)

    function debounce(func, delay) {
        let timer;
        return function (...args) {
          clearTimeout(timer);
          timer = setTimeout(() => {
            func(...args);
          }, delay);
        };
      }

    return (
        <>
        <Grid container>
            <Grid item xs={12}>
                <MaterialTable
                    detailPanel={
                        rowData => {
                            return (
                                <CategoryEditPanel
                                    Data={rowData}
                                    onClose={refreshTable}
                                />
                            )
                        }
                    }
                    tableRef={tableRef}
                    title={"Categories"}
                    columns={[
                        {
                            title: "EN", 
                            field: "NameEN",
                            defaultSort: "asc",
                        },
                        {
                            title: "FR",
                            field: "NameFR",
                        },
                        {
                            title: "NL",
                            field: "NameNL",
                        },
                        {
                            title: "Sportlink map",
                            field: "Sportlink",
                        },
                        {
                            title: "Outdoor",
                            field: "Outdoor",
                            type: "boolean",
                            headerStyle: { textAlign: "center"},
                            cellStyle: { textAlign: "center"},
                            render: rowData => boolRender(rowData.Outdoor)
                        },
                        {
                            title: "Indoor",
                            field: "Indoor",
                            type: "boolean",
                            headerStyle: { textAlign: "center"},
                            cellStyle: { textAlign: "center"},
                            render: rowData => boolRender(rowData.Indoor)
                        },
                        {
                            title: "Men",
                            field: "Men",
                            type: "boolean",
                            headerStyle: { textAlign: "center"},
                            cellStyle: { textAlign: "center"},
                            render: rowData => boolRender(rowData.Men)
                        },
                        {
                            title: "Ladies",
                            field: "Ladies",
                            type: "boolean",
                            headerStyle: { textAlign: "center"},
                            cellStyle: { textAlign: "center"},
                            render: rowData => boolRender(rowData.Ladies)
                        }
                    ]}
                    options={{
                        pageSize: rows,
                        pageSizeOptions: cst.itemsPerPage,
                        actionsColumnIndex: 50,
                        draggable: false,
                        emptyRowsWhenPaging:false,
                        detailPanelType: 'single',
                        sorting: true,
                        search: true,
                    }}
                    editable={{
                        onRowDelete: oldData => 
                            deleteModel(oldData.ID, token, CATEGORYURL)
                    }}
                    data={query => 
                        {   
                          return fetchData(token, query, CATEGORYURL).finally
                          ( response =>
                            {
                                debounceSearch(query);
                            }
                            
                          )
                        }}
                    onChangeRowsPerPage={(nbRows)=>setRows(nbRows)}
                    components={{
                        EditRow: props => { setIsTableEditing(true); return (<MTableEditRow {...props} />)},
                        Row: props => { setIsTableEditing(true); return (<MTableBodyRow {...props} />)},
                        Pagination: props=>(<TablePagination {...props} className={isTableEditing ?classes.disabled:null} />) ,
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} /> 
                                <GoAdminToolbar>

                                    {operation === "none" && (
                                            <>
                                                <GoPrimaryButton
                                                    startIcon={<AddIcon />}
                                                    onClick={() => {
                                                        operationChange("new");
                                                    }}
                                                >
                                                New
                                                </GoPrimaryButton>
                                            </>
                                    )}

                                    {operation === "new" && (
                                        <GoPaper>
                                            <CategoryEditPanel
                                                onClose={() => {
                                                    operationChange("none");
                                                    refreshTable();
                                                }}
                                                isNew={true}
                                            />
                                        </GoPaper>
                                    )}
                                    <GoPrimaryButton type="button" startIcon={<GetAppIcon />}
                                        disabled={exporting}
                                        onClick={handleDownloadClick}>Export to Excel

                                    </GoPrimaryButton>
                                    &nbsp;
                                    {exporting ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                </GoAdminToolbar>
                            </div>
                        )
                    }}
                />
            </Grid>  
        </Grid>
        
        </>
    )
}

export default Categories;